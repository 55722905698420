<!--
*************
*** PROPS ***
*************
* headers : [Object] - liste d'objets décrivant les en-têtes des colonnes
Le champ "label" est celui qui sera utilisé pour l'affichage
Le champ "sizeClasses" indique la taille à donner à la colonne. Défaut à 'auto' (Attention: "shrink" peut décaler les lignes du header)
Le champ "key" peut différer selon l'utilisation.
  - Si vous ne comptez pas personnaliser l'affichage des cases de la colonne, donc ne pas spécifier de slot. Alors le champ key doit correspondre à une propriété de l'objet passé en props "data". De cette façon le component affichera automatiquement la valeur de la propriété dans la case, dans une balise <p>
  - Maintenant si vous comptez personnaliser l'affichage des cases de la colonne OU si votre propriété est nested (sous-objet). Alors le champ key servira seulement à retrouver la colonne lors de la spécification du template. Il doit donc être unique mais pas forcément correspondre à une propriété de l'objet en props "data"
Exemple:
[
  {
    label: 'Object ID',
    key: 'objectId', //  Ici, on affichera la propriété "objectId" de l'objet
  },
  {
    label: 'Color',
    key: 'color', //  Ici, on affichera la propriété "color" de l'objet
  },
  {
    label: 'Digit',
    key: 'metadata.pagination.page', // Ici, vu que les sous-objets ne sont pas pris en compte, il servira juste pour le retrouver dans les slots
  },
  {
    label: 'Bool',
    key: 'bool', //  Ici, on affichera la propriété "bool" de l'objet
  }
]
* data : [Object] - liste d'objets à afficher
Exemple:
[
  {
    objectId: '1',
    color: 'red',
    bool: true,
    metadata: {
      pagination: {
        page: 10,
      },
    },
  },
  {
    objectId: '2',
    color: 'blue',
    bool: true,
    metadata: {
      pagination: {
        page: 22,
      },
    },
  },
  {
    objectId: '3',
    color: 'yellow',
    bool: false,
    metadata: {
      pagination: {
        page: 57,
      },
    },
  }
]
* loading : Boolean - sert à changer l'affichage du tableau par le contenu passé dans le slot "loading"
* showPagination : Boolean - permet d'afficher seulement une partie du data à la fois avec un système de pagination
* cursorPagination : Boolean - permet d'adapter le système de pagination si elle est effectuée avec un système de cursor. On a alors accès au champ "cursor" qui contient les champs "prev", "current" et "next" pour stocker les clés des pages précédentes et suivantes
* algorithmPagination : Boolean - permet d'adapter le système de pagination si elle est effectuée avec un algorithme (ici celui de l'API treezor). Dans ce cas ci, on a les boutons firstPage et lastPage qui disparaissent car nous n'avons pas toutes les données nécessaires pour le savoir
* limit : Number - nombre de lignes affichées par page
* offset : Number - indice de la première ligne affichée
* count : Number - nombre total de lignes
*************
*** SLOTS ***
*************
Doc sur les scope-slots
- https://fr.vuejs.org/v2/guide/components-slots.html#Slots-avec-portee
- https://www.digitalocean.com/community/tutorials/vuejs-scoped-component-slots
Il est possible de personnaliser plusieurs éléments du tableau grâce aux "scope-slots". On peut indiquer une balise "template" pour personnaliser l'affichage des cases d'une colonne, sinon on garde l'affichage par défaut qui est un simple <p>
* header - Avec ce slot on indique quel format auront les cases de header. L'objet "data" nous donne accès aux champs définis dans la liste "headers" en props.
  NOTE : si ce slot n'est pas spécifié, alors le champ "label" de l'objet passé en props "headers" sera affiché dans une balise <label>
  Exemple:
  <app-table :headers="headers" :data="data">
    <template slot="header" slot-scope="{ data }">
      <p><strong>{{ data.label }}</strong></p>
    </template>
  </app-table>
* CLE_CHAMP - Alors pour ce slot, le nom dépend du champ "key" que vous avez défini dans la liste "headers" en props. L'objet "data" nous donne accès aux objets passés en props "data"
  NOTE : si ce slot n'est pas spécifié pour une colonne, alors la propriété définie par le champ "key" du header de l'objet sera affiché dans une balise <p>
  Exemple:
  <app-table :headers="headers" :data="data">
    <template slot="objectId" slot-scope="{ data }">
      <p><strong>{{ data.objectId }}</strong></p>
    </template>
    <template slot="metadata.pagination.page" slot-scope="{ data }">
      <p><strong>{{ data.metadata.pagination.page }}</strong></p>
    </template>
  </app-table>
* empty-table - Avec ce slot on indique le contenu que l'on souhaite afficher lorsque la liste "data" en props est vide. On aura toujours les headers affichés, mais le contenu de la table est remplacé par ce template
  Exemple:
  <app-table :headers="headers" :data="data">
    <template slot="empty-table">
      <p>Oups, c'est vide !</p>
    </template>
  </app-table>
* loading - Avec ce slot on indique le contenu que l'on souhaite afficher lorsque le props "loading" est à true. De la même manière que pour le slot "empty-table", on aura toujours les headers affichés, mais le contenu de la table est remplacé par ce template
  Exemple:
  <app-table :headers="headers" :data="data">
    <template slot="loading">
      <p>LOADING...</p>
    </template>
  </app-table>
* afterLine - Avec ce slot on indique le contenu à afficher 'après la ligne' dans le tableau sans suivre la structure d'une ligne du tableau. Par exemple, des informations supplémentaires concernant la ligne.
Exemple:
<app-table :headers="headers" :data="data">
    <template slot="afterLine">
      <p>Ce composant s'insérera après une ligne sans suivre la structure du tableau</p>
    </template>
</app-table>
********************
*** Custom class ***
********************
Il est possible d'ajouter des classes CSS à une ligne en ajoutant la propriété cssClass aux objets de l'array data.
Il devra suivre le format demandé pour :class.
********************
*** Transition ***
********************
Les lignes du data sont englobées par un élément <transition-group name="data-line">.
Libre à vous de l'utliser pour effectuer des transitions lorsqu'un élément apparait ou disparait du data.
(Voir https://fr.vuejs.org/v2/guide/transitions.html#Transitions-de-liste)
!!!Attention!!! Pour animer un changement d'ordre de la liste, il faudra indiquer la clé à utiliser (autre que l'index) dans la prop dataKey
-->

<template>
  <section class="app-table">
    <div class="grid-x header">
      <div
        v-for="header in headers"
        :key="header.key"
        class="cell"
        :class="header.sizeClasses || 'auto' ">
        <slot v-if="$scopedSlots.header" name="header" :data="header"></slot>
        <label v-else>{{ header.label }}</label>
      </div>
    </div>

    <div v-if="loading" class="loading">
      <slot name="loading">CHARGEMENT...</slot>
    </div>
    <div v-else-if="data && data.length > 0" class="data">
      <transition-group name="data-line">
        <!-- CSS class custom si line a une propriété cssClasses -->
        <div
          v-for="(line, index) in data"
          class="dataLine"
          :key="dataKey ? line[dataKey] : `line-${index}`">
          <!-- CSS class custom si line a une propriété cssClasses -->
          <div
            class="line grid-x"
            :class="line.cssClass">
            <div
              v-for="header in headers"
              :key="`${index}-${header.key}`"
              class="cell"
              :class="header.sizeClasses || 'auto' ">
              <!-- Affichage custom si le slot est spécifié -->
              <slot
                v-if="$scopedSlots[header.key]"
                :name="header.key"
                :data="line"></slot>

              <!-- Affichage par défaut, juste du texte -->
              <p v-else>
                {{ line[header.key] }}
              </p>
            </div>
          </div>
          <!-- Possibilité d'ajouter un élément après la ligne
          (pour un élément déroulant apportant des informations supplémentaires par exemple) -->
          <slot
            v-if="$scopedSlots.afterLine"
            name="afterLine"
            class="afterLine"
            :data="line"></slot>
        </div>
      </transition-group>
    </div>
    <div v-else class="empty">
      <slot name="empty-table"></slot>
    </div>
    <div v-if="showPagination" class="grid-x align-middle align-right grid-margin-x pagination">
      <p v-if="!cursorPagination && !algorithmPagination" class="cell shrink text-pagination">{{ `${data.length ? offset + 1 : 0}-${Math.min(offset + limit, count)} de ${count}` }}</p>
      <div v-if="!cursorPagination && !algorithmPagination" class="cell shrink first button-pagination" :class="{disabled: isFirstPage}" @click="firstPage">
        <div></div>
        <div></div>
      </div>

      <div class="button-pagination" :class="{disabled: isFirstPage}" @click="previousPage">
        <div class="cell shrink previous"></div>
        <div v-if="cursorPagination || algorithmPagination" class="left">Page précédente</div>
      </div>
      <div class="button-pagination" :class="{disabled: isLastPage}" @click="nextPage">
        <div v-if="cursorPagination || algorithmPagination" class="right">Page suivante</div>
        <div class="cell shrink next"></div>
      </div>

      <div v-if="!cursorPagination && !algorithmPagination" class="cell shrink last button-pagination" :class="{disabled: isLastPage}" @click="lastPage">
        <div></div>
        <div></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'app-table',
  props: {
    headers: [Array],
    // Format [{ label: 'Lorem Ipsum', key: 'lorem' }]
    data: [Array],
    loading: [Boolean],
    showPagination: {
      type: Boolean,
      default: false,
    },
    limit: [Number],
    offset: {
      type: Number,
      default: 0,
    },
    count: [Number],
    dataKey: {
      type: [String],
      default: undefined,
    },
    cursorPagination: {
      type: Boolean,
      default: false,
    },
    cursor: {
      prev: null,
      current: null,
      next: null,
    },
    algorithmPagination: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // true si c'est la 1ere page du tableau
    isFirstPage() {
      if (this.cursorPagination) return this.cursor.prev === null;
      return (this.offset === 0);
    },
    // true si c'est la dernière page du tableau
    isLastPage() {
      if (this.cursorPagination) return this.cursor.next === null;
      if (this.algorithmPagination) return ((this.offset + this.limit) > (this.offset + this.count));
      return ((this.offset + this.limit + 1) > this.count);
    },
  },
  methods: {
    // remet la première page du tableau
    firstPage() {
      if (!this.cursorPagination && !this.algorithmPagination && !this.isFirstPage) {
        this.$emit('navigate', { offset: 0 });
      }
    },
    // page précédente du tableau
    previousPage() {
      if (!this.isFirstPage) {
        if (!this.cursorPagination) {
          this.$emit('navigate', { offset: this.offset - this.limit });
        } else {
          this.$emit('navigate', { cursor: this.cursor.prev });
        }
      }
    },
    // page suivante du tableau
    nextPage() {
      if (!this.isLastPage) {
        if (!this.cursorPagination) {
          this.$emit('navigate', { offset: this.offset + this.limit });
        } else {
          this.$emit('navigate', { cursor: this.cursor.next });
        }
      }
    },
    // dernière page du tableau
    lastPage() {
      if (!this.cursorPagination && !this.algorithmPagination && !this.isLastPage) {
        this.$emit('navigate', { offset: this.limit * Math.floor((this.count - 1) / this.limit) });
      }
    },
  },
};
</script>

<style lang="sass">
.app-table
  width: 100%
  position: relative
  margin-top: 1rem
  min-height: 5rem
  background-color: white
  .header
    padding-top: 2rem
    color: grey
    font-size: 12px
    div
      display: flex
      justify-content: flex-start
      align-items: center
      button
        padding: 0
  .data
    .line
      display: flex
      flex-direction: row
      align-items: center
      width: 100%
      height: 70px
      color: black
      text-align: left
      border-bottom: 1px solid lightgray
      p
        overflow: hidden
        text-overflow: ellipsis
  .data, .loading, .empty
    min-height: 3rem
  .pagination
    padding: 4rem
    .button-pagination
      font-weight: bold
      display: flex
      margin: 0 1rem
      .left, .right
        padding: 0 0.5rem
    .button-pagination:hover
      cursor: pointer
    .text-pagination
      padding-top: 0.3rem
    .previous, .next
      width: 0.8rem
      height: 0.8rem
      border: solid black
      border-width: 0 2px 2px 0
      transform-origin: center
      margin-top: 0.3rem
      &:hover
        border-color: black
    .next, .last :first-child
      transform: rotate(-45deg)
    .previous, .first :last-child
      transform: rotate(+135deg)
    .first
      margin-top: 0.3rem
      display: flex
      div:first-child, div:last-child
        border: solid black
        margin: 0.12rem
      div:first-child
        width: 0
        height: 1.15rem
        border-width: 0 2px 0 0
      div:last-child
        width: 0.8rem
        height: 0.8rem
        position: relative
        bottom: -2px
        border-width: 0 2px 2px 0
      &:hover div:first-child, &:hover div:last-child
        border-color: black
    .last
      margin-top: 0.3rem
      display: flex
      div:first-child, div:last-child
        border: solid black
        margin: 0.12rem
      div:last-child
        width: 0
        height: 1.15rem
        border-width: 0 0 0 2px
      div:first-child
        width: 0.8rem
        height: 0.8rem
        position: relative
        bottom: -2px
        border-width: 0 2px 2px 0
      &:hover div:first-child, &:hover div:last-child
        border-color: black
    .disabled div:first-child, .disabled div:last-child
      border-color: rgba(0, 0, 0, 0.4)
    .disabled:hover, .disabled:hover div:first-child, .disabled:hover div:last-child
      border-color: rgba(0, 0, 0, 0.4)
      cursor: initial
  .data-line-leave-active
    position: absolute // Permet aux lignes suivantes de prendre l espace disponible en transition
    width: 100% // Permet de garder la bonne longueur
    // En transition, les margins n ont plus d effet.
    // Le style suivant evite un saut de la ligne vers le bas avant application des transitions
    .line
      position: relative
      top: -1rem
  .empty, .loading
    padding: 100px 0
    text-align: center
</style>
