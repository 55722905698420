import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

// Get documents
const getDocuments = async (params) => {
  try {
    const response = await axios.get(`${API_URL}/admin/documents`, {
      params: {
        customerId: params.customerId,
      },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

// Get document
const getDocument = async (documentId) => {
  try {
    const response = await axios.get(`${API_URL}/trz/v1/documents/${documentId}`);
    return response.data.documents[0] ? response.data.documents[0] : null;
  } catch (error) {
    throw error;
  }
};

const getPreviewUrl = async (documentId) => {
  try {
    const response = await axios.get(`${API_URL}/admin/documents/${documentId}/download`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const prevalidate = async (documentId, status, comment) => {
  try {
    const response = await axios.post(`${API_URL}/admin/documents/${documentId}/pre-validate`, {
      status,
      comment,
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getDocuments = getDocuments;
api.getDocument = getDocument;
api.getPreviewUrl = getPreviewUrl;
api.prevalidate = prevalidate;

export default api;
