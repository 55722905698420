var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-facturations"},[_c('app-button',{staticClass:"create-button",on:{"click":_vm.toggleIsCreateActionEnabled}},[_vm._v("Créer une facturation")]),_c('app-button',{staticClass:"create-button",on:{"click":_vm.toggleIsCreateDiscountEnabled}},[_vm._v("Créer une remise")]),_c('transition',{attrs:{"name":"fade"}},[(_vm.isCreateActionEnabled && !_vm.isCreateDiscountEnabled)?_c('div',{staticClass:"create-content"},[_c('app-select',{attrs:{"value":_vm.createActionSelected,"options":_vm.optionSelectComputed,"placeholder":"Choisir une facturation"},on:{"input":function (value) { return _vm.createActionSelected = value; }}}),_c('app-button',{attrs:{"disabled":_vm.createLoading},on:{"click":_vm.createNonAutoAction}},[_vm._v("Valider")])],1):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(!_vm.isCreateActionEnabled && _vm.isCreateDiscountEnabled)?_c('div',{staticClass:"create-content"},[_c('app-select',{attrs:{"value":_vm.createDiscountSelected,"options":_vm.discountsSelectComputed,"placeholder":"Choisir une remise"},on:{"input":function (value) { return _vm.createDiscountSelected = value; }}}),_c('app-button',{attrs:{"disabled":_vm.createLoading},on:{"click":_vm.createNonAutoDiscount}},[_vm._v("Valider")])],1):_vm._e()]),(_vm.fees && _vm.fees.length !== 0)?_c('app-table',_vm._b({attrs:{"headers":_vm.headers,"data":_vm.fees},on:{"navigate":function (e) { _vm.getExistingFeeWithTheirAction(e.offset); }},scopedSlots:_vm._u([{key:"feeId",fn:function(ref){
var data = ref.data;
return [_c('p',{staticClass:"fee"},[_vm._v("Frais")]),_c('p',[_vm._v(_vm._s(data.feeId))])]}},{key:"feePrice",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.price / 100)+"€")])]}},{key:"feeCreatedAt",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(_vm.dayjs(data.createdAt).format('DD-MM-YYYY')))])]}},{key:"actionId",fn:function(ref){
var data = ref.data;
return [_c('p',{staticClass:"action"},[_vm._v("Action")]),_c('p',{staticClass:"action-id"},[_vm._v(_vm._s(data.action.actionId))])]}},{key:"actionName",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.action.name))])]}},{key:"actionCreatedAt",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(_vm.dayjs(data.action.createdAt).format('DD-MM-YYYY')))])]}}],null,false,3562099441)},'app-table',_vm.tableConfig,false)):(_vm.fees && _vm.fees.length === 0)?_c('div',{staticClass:"no-results"},[_vm._v("Aucun frais")]):_c('app-loader')],1)}
var staticRenderFns = []

export { render, staticRenderFns }