<template>
  <div>
    <app-table v-if="cards && cards.length !== 0" :headers="headers" :data="cards" v-bind="tableConfig" @navigate="(e) => { getCards(e.offset); }">
      <template slot="physical" slot-scope="{ data }">
        <p v-if="data.physical">Physique</p>
        <p v-else>Virtuelle</p>
      </template>

      <template slot="limitations" slot-scope="{ data }">
        <p>{{data.limitPaymentAll}} | {{data.limitPaymentYear}} | {{data.limitPaymentMonth}} | {{data.limitPaymentWeek}} | {{data.limitPaymentDay}}</p>
      </template>

      <template slot="createdDate" slot-scope="{ data }">
        <p>{{new Date(data.createdDate * 1000).toLocaleString()}}</p>
      </template>

      <template slot="pinStatus" slot-scope="{ data }">
        <p>{{ data.pinTryExceeds ? 'Bloqué' : 'Débloqué' }}</p>
      </template>

      <template slot="unlock" slot-scope="{ data }">
        <button v-if="data.pinTryExceeds" v-on:click="unlockCardPin(data.cardId)">Débloquer</button>
        <button v-else disabled>Débloquer le PIN</button>
      </template>
    </app-table>
    <div class="no-results" v-else-if="cards && cards.length === 0"/>
    <app-loader v-else/>
  </div>
</template>

<script>
import apiCard from '@/services/api/card';

export default {
  name: 'userCartes',
  data() {
    return {
      cards: null,
      headers: [
        { label: 'CardId', key: 'cardId' },
        { label: 'Type', key: 'physical' },
        { label: 'Nom', key: 'deliveryLastname' },
        { label: 'Adresse livraison', key: 'deliveryAddress1' },
        { label: 'Limitations (tout | année | mois | semaine | jour)', key: 'limitations', sizeClasses: 'small-4' },
        { label: 'Statut', key: 'statusCode' },
        { label: 'Créé le', key: 'createdDate' },
        { label: 'Statut du PIN', key: 'pinStatus' },
        { label: 'Action', key: 'unlock' },
      ],
      tableConfig: {
        showPagination: true,
        loading: false,
        limit: 10,
        offset: 0,
        count: 0,
      },
    };
  },
  watch: {
    $route: 'fetchData',
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      // Calculate the offset with the pageNumber route parameter and the row limit in the table
      const offset = this.$route.query.pageNumber * this.tableConfig.limit - this.tableConfig.limit;

      // Get all cards of a user
      const response = await apiCard.getCards({
        treezorUserId: this.$route.params.userId,
        isPhysical: 'true',
        pageNumber: 0,
        pageCount: 20,
      });

      // Update variables for the AppTable component
      this.cards = response.data;
      this.tableConfig.offset = offset;
      this.tableConfig.count = response.metadata.count;
    },
    // Function called when an arrow of the AppTable is pressed
    async getCards(offset) {
      // Calculate the pageNumber with the offset and the row limit in the table
      const pageNumber = offset / this.tableConfig.limit + 1;
      // Condition to avoid reloading the same page when clicking multiple times on arrows
      if (pageNumber !== Number(this.$route.query.pageNumber)) await this.$router.replace({ name: 'user-cartes', query: { pageNumber: pageNumber.toString() } });
    },

    // Déblocage du PIN d'une carte
    async unlockCardPin(cardId) {
      await apiCard.unlockPin(cardId);
      await this.fetchData();
    },
  },
};
</script>

<style lang="sass">
.dataLine
  .line
    .cell:first-child
      color: #006D72
      font-weight: bold
</style>
