<template>
  <section class="transactions">
    <p id="breadcrum">Transactions</p>

    <h2 id="title">Transactions</h2>

    <div>
      <input type="checkbox" id="checkboxFilter" v-if="transactions && transactions.length !== 0" v-model="checked" @change="changeFilter" />
      <label for="checkboxFilter">Only risky transactions (>= 500€)</label>

      <app-table class="transactions-table" v-if="transactions && transactions.length !== 0" :headers="headers" :data="transactions" v-bind="tableConfig" @navigate="(e) => { getTransactions(e.offset); }">
        <template slot="date.creation" slot-scope="{ data }">
          <p>{{ new Date(data.date.creation).toLocaleString() }}</p>
        </template>

        <template v-if="data.date.settlement" slot="date.settlement" slot-scope="{ data }">
          <p>{{ new Date(data.date.settlement).toLocaleString() }}</p>
        </template>

        <template slot="amount" slot-scope="{ data }">
          {{ data.amount.amount.toString().substr(0, data.amount.amount.toString().length - 2) }}.
          {{ data.amount.amount.toString().substr(data.amount.amount.toString().length - 2) }}
          {{ data.amount.currency }}
        </template>

        <template slot="infos" slot-scope="{ data }">
          <p>{{ data.label.split('(')[0] }}</p>
          <p>{{ data.direction }}</p>
        </template>

      </app-table>
      <div v-else-if="transactions && transactions.length === 0">No transactions</div>
      <app-loader v-else/>
    </div>
  </section>
</template>

<script>
import apiTransaction from '../services/api/transaction';

export default {
  name: 'transactions',
  data() {
    return {
      transactions: null,
      checked: false,

      headers: [
        { label: 'Identifiant', key: 'objectId' },
        { label: 'WalletId', key: 'walletId' },
        { label: 'Date de l\'opération', key: 'date.creation' },
        { label: 'Date de règlement', key: 'date.settlement' },
        { label: 'Montant', key: 'amount' },
        { label: 'Informations', key: 'infos' },
        { label: 'Statut', key: 'status' },
      ],

      tableConfig: {
        showPagination: true,
        loading: false,
        limit: 20,
        offset: 0,
        count: 0,
      },
    };
  },
  watch: {
    $route: 'fetchData',
  },
  mounted() {
    this.fetchData();

    this.checked = this.$route.query.onlyRiskyTransaction;
  },
  methods: {
    async fetchData() {
      this.tableConfig.loading = true;

      // Get all operations
      const tmpTransactions = await apiTransaction.getAllTransactions(
        {
          limit: this.tableConfig.limit,
          offset: this.$route.query.offset,
          onlyRiskyTransaction: this.$route.query.onlyRiskyTransaction,
        },
      );

      this.transactions = tmpTransactions.data;

      this.tableConfig.limit = tmpTransactions.metadata.limit;
      this.tableConfig.offset = tmpTransactions.metadata.offset;
      this.tableConfig.count = tmpTransactions.metadata.count;

      this.tableConfig.loading = false;
    },
    // Function called when an arrow of the AppTable is pressed
    async getTransactions(offset) {
      if (offset !== Number(this.$route.query.offset)) {
        await this.$router.replace({ name: 'transactions', query: { offset: offset.toString(), onlyRiskyTransaction: this.checked } });
      }
    },
    async changeFilter() {
      // Change the table with only risky transactions if the checkbox is checked
      if (this.checked !== this.$route.query.onlyRiskyTransaction) {
        await this.$router.replace({ name: 'transactions', query: { offset: 0, onlyRiskyTransaction: this.checked } });
      }
    },
  },
};
</script>

<style lang="sass">
.transactions
  margin-bottom: 0
  padding-top: 1rem
  min-height: 100vh
#title
  padding-left: 4rem
.transactions-table
  padding: 0 4rem 4rem 4rem
#checkboxFilter
  margin-left: 4rem
</style>
