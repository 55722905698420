<template>
  <div>
    <div class="actions" v-if="user">
      <div>
        <h2>Demander une nouvelle review KYC / KYB</h2>
        <p>Statut actuel :
          <span v-if="user.treezorUser.kycReview === 0">Pas demandé</span>
          <span v-if="user.treezorUser.kycReview === 1">En cours</span>
          <span v-if="user.treezorUser.kycReview === 2">Validé</span>
          <span v-if="user.treezorUser.kycReview === 3">Refusé</span>
        </p>
         <p>Commentaire Treezor:
          <span>{{ user.customer.kycReviewComment }}</span>
        </p>
        <app-button ref="reviewDocuments" @click="reviewDocuments">Demander</app-button>
      </div>

      <h2>Clôturer le compte</h2>
      <p>Statut actuel :
        <span v-if="user.customer.closingStatus === 'PENDING'">En attente</span>
        <span v-if="disableCloseAccountButton">Clôturé</span>
        <span v-else>Non clôturé</span>
      </p>
      <app-button
        ref="closeAccount"
        @click="closeAccount(CloseAccountUserType.ADMIN, 'toCloseAccount')"
        :is-loading="loadings.toCloseAccount"
        :disabled="disableCloseAccountButton || isLoading">
        Clôturer
      </app-button>
      <app-button
        ref="closeAccount"
        @click="closeAccount(CloseAccountUserType.CUSTOMER, 'toCloseClientRequest')"
        :is-loading="loadings.toCloseClientRequest"
        :disabled="disableCloseAccountButton || isLoading">
        Clôturer à la demande du client
      </app-button>

      <h2>Remettre à zéro le compteur d'essais de modification du code secret</h2>
      <p>Nombre d'essais actuels : {{ user.customer.secretCodeCounter }}</p>
      <app-button
        ref="resetSecretCodeCounter"
        @click="resetSecretCodeCounter"
        :is-loading="loadings.toResetSecretCodeCounter"
        :disabled="isLoading">
        Remettre à zéro
      </app-button>

      <h2>{{ user.customer.isActive ? 'Bloquer' : 'Débloquer' }} l'utilisateur</h2>
      <p>Statut actuel : {{ user.customer.isActive ? 'Actif' : 'Inactif' }}</p>
      <app-button
        v-if="user.customer.isActive"
        @click="changeUserStatus"
        :is-loading="loadings.lockUnlockUser"
        :disabled="isLoading">
        Bloquer
      </app-button>
      <app-button
        v-else
        @click="changeUserStatus"
        :is-loading="loadings.lockUnlockUser"
        :disabled="isLoading">
        Débloquer
      </app-button>
    </div>

    <app-loader v-else/>
    <app-message ref="errorModal"/>
  </div>
</template>

<script>
import apiUser from '@/services/api/user';
import CloseAccountUserType from '../services/enums/CloseAccountUserType';

export default {
  name: 'userActions',

  data() {
    return {
      user: null,
      CloseAccountUserType,
      loadings: {
        toCloseAccount: false,
        toCloseClientRequest: false,
        toResetSecretCodeCounter: false,
        lockUnlockUser: false,
      },
    };
  },

  watch: {
    $route: 'fetchData',
  },

  async created() {
    await this.fetchData();
  },

  computed: {
    isLoading() {
      return Object.values(this.loadings).includes(true);
    },

    disableCloseAccountButton() {
      return this.user.customer.closingStatus === 'CLOSED' || this.user.treezorUser.userStatus === 'CANCELLED';
    },

    disableKycButton() {
      return !(this.user.customer.initialPayin && ((this.user.customer.type === 'INDIVIDUAL' && this.user.customer.livenessStatus === 'PROCESSED')
        || this.user.customer.type === 'BUSINESS') && (this.user.treezorUser.kycReview === 0 || this.user.treezorUser.kycReview === 3));
    },
  },

  methods: {
    async fetchData() {
      this.user = await apiUser.getUser(this.$route.params.userId);
    },

    async reviewDocuments() {
      try {
        this.user.treezorUser = await apiUser.reviewDocuments(this.$route.params.userId);

        this.$refs.errorModal.show({
          title: 'Confirmation message',
          text: 'The KYC review request has been sent !',
        });
      } catch (e) {
        this.$refs.errorModal.show({
          title: 'Error message',
          text: e.response.data.errors[0].message,
        });
      }
    },

    async closeAccount(userType, loading) {
      this.loadings[loading] = true;

      try {
        await apiUser.closeAccount(this.user.customer.customerId, userType);

        this.$refs.errorModal.show({
          title: 'Confirmation message',
          text: 'The closing process has started ',
        });
      } catch (e) {
        this.$refs.errorModal.show({
          title: 'Error message',
          text: e.response.data.errors[0].message,
        });
      } finally {
        this.loadings[loading] = false;
      }
    },

    async changeUserStatus() {
      this.loadings.lockUnlockUser = true;

      try {
        this.user.customer = await apiUser.updateUser(this.$route.params.userId, {
          isActive: !this.user.customer.isActive,
        });
        this.$refs.errorModal.show({
          title: 'Confirmation message',
          text: 'User status has been changed !',
        });
      } catch (e) {
        this.$refs.errorModal.show({
          title: 'Error message',
          text: e.response.data.errors[0].message,
        });
      } finally {
        this.loadings.lockUnlockUser = false;
      }
    },

    async resetSecretCodeCounter() {
      this.loadings.toResetSecretCodeCounter = true;

      try {
        await apiUser.resetSecretCodeCounter(this.user.customer.customerId);

        this.$refs.errorModal.show({
          title: 'Confirmation message',
          text: 'Le compteur a été remis à zéro',
        });
      } catch (e) {
        this.$refs.errorModal.show({
          title: 'Error message',
          text: e.response.data.errors[0].message,
        });
      } finally {
        this.loadings.toResetSecretCodeCounter = false;
      }
    },
  },
};
</script>

<style lang="sass">
.actions
  padding-top: 2rem
  padding-bottom: 3rem
.app-button
  font-weight: bold
  padding: 1.25rem 5rem!important
</style>
