import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

// Get users
const getUsers = async (params) => {
  try {
    const response = await axios.get(`${API_URL}/admin/customers`, {
      params: {
        pageNumber: params.pageNumber,
        pageCount: params.limit,
        ...(params.closingStatus && { closingStatus: params.closingStatus }),
        ...(params.readyForReviewStatus && { readyForReviewStatus: params.readyForReviewStatus }),
        ...(params.search && { search: params.search }),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Get user
const getUser = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/admin/customers/${userId}`);
    return response.data.data ? response.data.data : null;
  } catch (error) {
    throw error;
  }
};

// Update user
const updateUser = async (userId, user) => {
  try {
    const response = await axios.put(`${API_URL}/admin/customers/${userId}`, user);
    return response.data.data ? response.data.data : null;
  } catch (error) {
    throw error;
  }
};

// Ask for a KYC review
const reviewDocuments = async (userId) => {
  try {
    const response = await axios.put(`${API_URL}/trz/v1/users/${userId}/Kycreview`);
    return response.data.users[0] ? response.data.users[0] : null;
  } catch (error) {
    throw error;
  }
};

// Get children of an user
const getLinkedUsers = async (treezorUserId, params) => {
  try {
    const response = await axios.get(`${API_URL}/admin/customers/children/${treezorUserId}`, {
      params: {
        pageNumber: params.pageNumber,
        pageCount: params.limit,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const closeAccount = async (customerId, userType) => {
  try {
    const response = await axios.post(`${API_URL}/admin/customers/${customerId}/close-account`, {}, {
      params: {
        userType,
      },
    });
    return response.data.data ? response.data.data : null;
  } catch (error) {
    throw error;
  }
};

const resetSecretCodeCounter = async (customerId) => {
  try {
    const response = await axios.post(`${API_URL}/admin/customers/${customerId}/reset-secret-code-counter`);
    return response.data.data ? response.data.data : null;
  } catch (error) {
    throw error;
  }
};

const validateLiveness = async (customerId) => {
  try {
    await axios.put(`${API_URL}/customers/${customerId}/kyc-liveness`);
    return;
  } catch (error) {
    throw error;
  }
};

const changeReadyForReviewStatus = async (customerId, readyForReviewStatus = 'REFUSED') => {
  try {
    await axios.put(`${API_URL}/admin/customers/${customerId}/change-ready-for-review-status`, { readyForReviewStatus });
    return;
  } catch (error) {
    throw error;
  }
};

const createKycLiveness = async (userId) => {
  try {
    await axios.post(`${API_URL}/admin/customers/${userId}/kyc-liveness`, {});
    return;
  } catch (error) {
    throw error;
  }
};

// Get impersonate jwt token
const getJWTImpersonate = async (treezorUserId) => {
  try {
    const response = await axios.get(`${API_URL}/auth/admin/impersonate/${treezorUserId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getUsers = getUsers;
api.getUser = getUser;
api.updateUser = updateUser;
api.reviewDocuments = reviewDocuments;
api.getLinkedUsers = getLinkedUsers;
api.closeAccount = closeAccount;
api.resetSecretCodeCounter = resetSecretCodeCounter;
api.validateLiveness = validateLiveness;
api.changeReadyForReviewStatus = changeReadyForReviewStatus;
api.createKycLiveness = createKycLiveness;
api.getJWTImpersonate = getJWTImpersonate;

export default api;
