<template>
  <nav>
    <slot v-if="$route.meta.requiresAuth">
      <router-link :to="{ name: 'login' }"><img class="logo" src="../assets/img/logo-kalveen-white.png" alt="logo kalveen"/></router-link>
      <router-link :to="{ name: 'users', query: { pageNumber: '1' } }">🧑‍🤝‍🧑 Utilisateurs</router-link>
      <router-link :to="{ name: 'transactions' }">↔️ Transactions</router-link>
      <router-link :to="{ name: 'actions' }">↔️ Actions</router-link>
      <router-link :to="{ name: 'alerts' }">↔️ Alertes</router-link>
    </slot>
  </nav>
</template>

<script>
export default {
  name: 'navigation',
};
</script>

<style lang="sass">
nav
  position: fixed
  left: 0
  top: 0
  padding-top: 1rem
  width: 200px
  min-height: 100vh
  background: $main-color
  a
    display: block
    padding: 1rem
    font-weight: bold
    font-size: 1.2rem
    color: white
    &.router-link-exact-active
      color: $light-color
.logo
  width: 12rem
#breadcrum
  padding-left: 4rem
</style>
