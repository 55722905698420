import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

// Get beneficiaries of an user
const getBeneficiaries = async (params) => {
  try {
    const response = await axios.get(`${API_URL}/admin/beneficiaries`, {
      params: {
        userId: params.userId,
        pageNumber: params.pageNumber,
        pageCount: params.limit,
      },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getBeneficiaries = getBeneficiaries;

export default api;
