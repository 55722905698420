<template>
  <button :class="{ 'small-button': small }" class="app-button" :type="type" @click="handleClick" :disabled="isLoading">
    <app-spinner v-if="isLoading" :small="true" ></app-spinner>
    <slot v-else></slot>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      default: 'button',
      validator: (val) => ['button', 'submit'].indexOf(val) !== -1,
    },
    isLoading: {
      type: Boolean,
      default: false,
      require: false,
    },
    small: {
      type: Boolean,
      default: false,
      require: false,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    handleClick(evt) {
      this.$emit('click', evt);
    },
  },
};
</script>

<style lang="sass">
.small-button
  padding: 0.3rem 0.4rem !important

.app-button
  margin: 0.2rem
  padding: 0.8rem 2rem 0.8rem
  background: $main-color
  color: white
  border: 0
  border-radius: $global-border-radius
  text-transform: uppercase
  font-size: $global-font-size
  transition: all ease-in-out 0.2s
  cursor: pointer

  &:disabled
    background: $light-grey
    cursor: default
    color: $medium-grey

  &:hover:not(&:disabled)
    background: darken($main-color, 5%)

  &:focus
    outline: 0

  &.light
    background: $light-color
    color: $text-color
    &:hover
      background: darken($light-color, 5%)

  .app-spinner
    margin: auto
    &.small
      height: 18px
      width: 18px
    svg
      border-width: 1px
</style>
