<template>
  <section class="user">
    <p id="breadcrum"><router-link :to="{ name: 'users', query: { pageNumber: '1' } }">Utilisateurs</router-link > &raquo; Utilisateur</p>

    <div id="tabs" class="grid-x">
      <router-link class="tab" :to="{ name: 'user-informations' }">Informations</router-link>
      <router-link class="tab" :to="{ name: 'user-transactions' }">Opérations</router-link>
      <router-link class="tab" :to="{ name: 'user-virements' }">Virements</router-link>
      <router-link class="tab" :to="{ name: 'user-beneficiaires', query: { pageNumber: 1 } }">Bénéficiaires</router-link>
      <router-link class="tab" :to="{ name: 'user-cartes', query: { pageNumber: 1 } }">Cartes</router-link>
      <router-link class="tab" :to="{ name: 'user-documents', query: { pageNumber: 1 } }">KYC/KYB</router-link>
      <router-link class="tab" :to="{ name: 'user-utilisateurs-lies', query: { pageNumber: 1 } }">Utilisateurs liés</router-link>
      <router-link class="tab" :to="{ name: 'user-actions' }">Actions</router-link>
      <router-link class="tab" :to="{ name: 'user-facturations', query: { pageNumber: 1 } }">Facturations</router-link>
      <router-link class="tab" :to="{ name: 'user-gcs'}">Conciergerie</router-link>
    </div>
    <router-view class="tab-container"/>

  </section>
</template>

<script>
export default {
  name: 'user',
};
</script>

<style lang="sass">
.user
  padding-top: 1rem
#tabs
  overflow-x: auto
  flex-flow: nowrap
  .tab
    display: flex
    margin: 0 0.05rem
    padding: 0.75rem 2rem
    align-items: center
    background-color: $light-grey
    color: $medium-grey
    cursor: pointer
    border-top-right-radius: 8px
    border-top-left-radius: 8px
    font-weight: bold
  .tab.router-link-active
    background-color: white
    color: black
.tab-container
  margin-top: 0.75rem
  padding: 0.05rem 4rem 2rem 4rem
  background-color: white
  min-height: 100vh
  text-align: left
.no-results
  margin-top: 2rem
  margin-left: 1rem
  font-weight: bold
.dataLine
  .line
    .cell:first-child
      color: #006D72
      font-weight: bold
</style>
