<template>
  <div>
    <app-table v-if="linkedUsers && linkedUsers.length !== 0" :headers="headers" :data="linkedUsers" v-bind="tableConfig" @navigate="(e) => { getLinkedUsers(e.offset); }">
      <template slot="filiation">
        <p>Enfant</p>
      </template>
      <template slot="parentType" slot-scope="{ data }">
        <p v-if="data.parentType === 'LEADER'">Gérant</p>
        <p v-else-if="data.parentType === 'SHAREHOLDER'">Actionnaire</p>
        <p v-else-if="data.parentType === 'EMPLOYEE'">Salarié</p>
      </template>
      <template slot="createdAt" slot-scope="{ data }">
        <p>{{new Date(data.createdAt).toLocaleString()}}</p>
      </template>
    </app-table>
    <div class="no-results" v-else-if="linkedUsers && linkedUsers.length === 0">Aucun utilisateur lié</div>
    <app-loader v-else/>
  </div>
</template>

<script>
import apiUser from '@/services/api/user';

export default {
  name: 'userUtilisateursLies',
  data() {
    return {
      linkedUsers: null,
      headers: [
        { label: 'Id', key: 'treezorUserId' },
        { label: 'Prénom', key: 'firstname' },
        { label: 'Nom', key: 'lastname' },
        { label: 'Filiation', key: 'filiation' },
        { label: 'Type', key: 'parentType' },
        { label: 'Créé le', key: 'createdAt' },
      ],
      tableConfig: {
        showPagination: true,
        loading: false,
        limit: 10,
        offset: 0,
        count: 0,
      },
    };
  },
  watch: {
    $route: 'fetchData',
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      // Calculate the offset with the pageNumber route parameter and the row limit in the table
      const offset = this.$route.query.pageNumber * this.tableConfig.limit - this.tableConfig.limit;

      // Get all linked users of an user
      const response = await apiUser.getLinkedUsers(this.$route.params.userId, {
        limit: this.tableConfig.limit,
        pageNumber: this.$route.query.pageNumber,
      });

      // Update variables for the AppTable component
      this.linkedUsers = response.data;
      this.tableConfig.offset = offset;
      this.tableConfig.count = response.metadata.count;
    },
    // Function called when an arrow of the AppTable is pressed
    async getLinkedUsers(offset) {
      // Calculate the pageNumber with the offset and the row limit in the table
      const pageNumber = offset / this.tableConfig.limit + 1;

      // Condition to avoid reloading the same page when clicking multiple times on arrows
      if (pageNumber !== Number(this.$route.query.pageNumber)) await this.$router.replace({ name: 'user-utilisateurs-lies', query: { pageNumber: pageNumber.toString() } });
    },
  },
};
</script>
