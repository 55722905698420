const ActionTargetEntity = {
  SUBSCRIPTION: 'Abonnement',
  PAYOUT: 'Virement sortant',
  PAYIN: 'Virement entrant',
  BENEFICIARY: 'Bénéficiaire',
  CARD: 'Carte',
  OTHER: 'Autre',
  CUSTOMER: 'Utilisateur',
  DOCUMENT: 'Document',
};

export default ActionTargetEntity;
