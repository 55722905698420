const ActionType = {
  LOGIN_IDENTIFIERS: 'Connexion avec identifiants (en attente de code secret)',
  LOGIN_SECRETCODE: 'Connexion avec code secret (connexion validée)',
  ADMIN_LOGIN: 'Connexion administrateur',
  PUT_USER: 'Modification utilisateur',
  POST_PAYOUT: 'Création de virement sortant',
  POST_PAYIN: 'Création de virement entrant',
  POST_BENEFICIARY: 'Création de bénéficiaire',
  UNPROCESSED: 'Non traité',
  POST_CARD: 'Création de carte',
  OTHER: 'Autre',
  POST_PAYOUT_ERR: 'ERREUR: création de virement sortant',
  WEBHOOK_PAYOUT_CREATE: 'WEBHOOK: création de virement sortant',
  WEBHOOK_PAYOUT_UPDATE: 'WEBHOOK: mise à jour de virement sortant',
  WEBHOOK_PAYOUT_CANCEL: 'WEBHOOK: annulation de virement sortant',
  WEBHOOK_PAYOUT_REFUND_UPDATE: 'WEBHOOK: mise à jour remboursement virement sortant',
  WEBHOOK_SEPA_SDDR_RECEPTION: 'WEBHOOK: sepa sddr reception',
  WEBHOOK_SEPA_REJECT_SDDR_CORE: 'WEBHOOK: rejet de prélèvement pour Particulier',
  WEBHOOK_SEPA_REJECT_SDDR_B2B: 'WEBHOOK: rejet de prélèvement pour Professionnel',
  WEBHOOK_PAYIN_UPDATE: 'WEBHOOK: mise à jour virement entrant',
  WEBHOOK_PAYIN_REFUND_UPDATE: 'WEBHOOK: mise à jour remboursement virement entrant',
  WEBHOOK_PAYIN_REFUND_CREATE: 'WEBHOOK: création remboursement virement entrant',
  WEBHOOK_PAYIN_REFUND_CANCEL: 'WEBHOOK: annulation remboursement virement entrant',
  WEBHOOK_RECALLR_NEED_RESPONSE: 'WEBHOOK: recallr need reponse',
  WEBHOOK_SEPA_RETURN_SCTR: 'WEBHOOK: sepa return sct',
  WEBHOOK_KYC_LIVENESS_CREATE: 'WEBHOOK: processus liveness débuté',
  WEBHOOK_KYC_LIVENESS_UPDATE: 'WEBHOOK: mise à jour de l\'état du processus liveness',
  WEBHOOK_DOCUMENT_UPDATE: 'WEBHOOK: mise à jour état document',
  WEBHOOK_CARD_TRANSACTION_CREATE: 'WEBHOOK: création transaction par carte',
  WEBHOOK_USER_KYCREVIEW: 'WEBHOOK: réponse validation KYC',
  WEBHOOK_USER_KYCREQUEST: 'WEBHOOK: création demande de validation KYC',
  MASS_PAYOUT: 'Création import de virement',
  MASS_PAYOUT_ERR: 'ERREUR: création import de virement',
  SUBSCRIPTION: 'Abonnement',
  POST_PAYIN_CHEQUE: 'Création demande de dépôt de chèque',
  WEBHOOK_PAYOUT_REFUND_CREATE: 'Remboursement de virement sortant',
  WEBHOOK_PAYIN_CREATE: 'Création dépôt de chèque',
  POST_VIRTUAL_CARD: 'Création carte virtuelle',
  POST_VIRTUAL_CARD_ERR: 'Échec création carte virtuelle',
  DELETE_VIRTUAL_CARD: 'Suppression carte virtuelle',
  POST_PHYSICAL_CARD: 'Création carte physique',
  WITHDRAWAL: 'Retrait',
  WITHDRAWAL_ERR: 'Échec retrait',
  SET_PIN: 'Changement de code PIN',
  WEBHOOK_CARD_TRANSACTION_CREATE_ERR: 'Échec création transaction par carte',
  IRREGULAR_USE: 'Utilisation anormale',
  TRANSFER_PROCEDURE: 'Intervention conseiller',
  PHOTOCOPY_CHEQUE_LESS_YEAR: 'Photocopie de chèque de moins d\'un an',
  PHOTOCOPY_CHEQUE_MORE_YEAR: 'Photocopie de chèque de plus d\'un an',
  RESEARCH_UNJUSTIFIED_SEPA: 'Contestation SEPA non justifiée',
  RESEARCH_UNJUSTIFIED_CARD_TRANSACTION_FR: 'Contestation transaction FR par carte non justifiée',
  RESEARCH_UNJUSTIFIED_CARD_TRANSACTION_NOT_FR: 'Contestation transaction non FR par carte non justifiée',
  BALANCE_CERTIFICATE: 'Attestation de solde',
  RESEARCH_UNKNOWN_PLI_ADDRESS: 'Recherche de l\'adresse PLI inconnue',
  SEIZURE_AND_SALE: 'Saisies-attribution ou saisies-conservatoires',
  PAYMENT_OF_SUPPORT_MANAGEMENT: 'Gestion paiement pension alimentaire',
  OPPOSITION_SEPA: 'Opposition tout prélèvement SEPA',
  UNAUTHORIZED_PAYMENT_TRANSACTIONS: 'Opérations de paiement non autorisées',
  WEBHOOK_CARD_REGISTER_3DS: 'Enregistrement 3DS',
  WEBHOOK_TRANSACTION_CREATE: 'Création de transaction',
  DISCOUNT_KLASSIC_1_MONTH: 'Remise 1 mois KLASSIC',
  DISCOUNT_ACCESS_1_MONTH: 'Remise 1 mois ACCESS',
  DISCOUNT_PREMIUM_1_MONTH: 'Remise 1 mois PREMIUM',
  DISCOUNT_FIRST_1_MONTH: 'Remise 1 mois FIRST',
  DISCOUNT_BUSINESS_1_MONTH: 'Remise 1 mois BUSINESS',
  DISCOUNT_AFFAIR_1_MONTH: 'Remise 1 mois AFFAIR',
  DISCOUNT_IRREGULAR_USE: 'Remise : utilisation anormale',
  DISCOUNT_OPPOSITION_SEPA: 'Remise : Opposition tout prélèvement SEPA',
  DISCOUNT_SEIZURE_AND_SALE: 'Remise : Saisies-attribution ou saisies-conservatoires',
  DISCOUNT_TRANSFER_PROCEDURE: 'Remise : Intervention conseiller',
  DISCOUNT_BALANCE_CERTIFICATE: 'Remise : Attestation de solde',
  DISCOUNT_RESEARCH_UNKNOWN_PLI_ADDRESS: 'Remise : Recherche de l\'adresse PLI inconnue',
  DISCOUNT_PAYMENT_OF_SUPPORT_MANAGEMENT: 'Remise : Gestion paiement pension alimentaire',
  DISCOUNT_UNAUTHORIZED_PAYMENT_TRANSACTIONS: 'Remise : Opérations de paiement non autorisées',
  DISCOUNT_RESEARCH_UNJUSTIFIED_CARD_TRANSACTION_FR: 'Remise : Contestation transaction FR par carte non justifiée',
  DISCOUNT_RESEARCH_UNJUSTIFIED_CARD_TRANSACTION_NOT_FR: 'Remise : Contestation transaction non FR par carte non justifiée',
  DISCOUNT_PHOTOCOPY_CHEQUE_LESS_YEAR: 'Remise : Photocopie de chèque de moins d\'un an',
  DISCOUNT_PHOTOCOPY_CHEQUE_MORE_YEAR: 'Remise : Photocopie de chèque de plus d\'un an',
  DISCOUNT_RESEARCH_UNJUSTIFIED_SEPA: 'Remise : Contestation SEPA non justifiée',
};

export default ActionType;
