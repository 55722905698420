import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

// Get wallets with given params
const getWallets = async (params) => {
  try {
    const response = await axios.get(`${API_URL}/trz/v1/wallets`, {
      params: {
        ...(params?.userId && { userId: params.userId }),
        ...(params?.walletTypeId && { walletTypeId: params.walletTypeId }),
      },
    });
    return response.data.wallets;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getWallets = getWallets;

export default api;
