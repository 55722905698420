import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

/**
 * Obtient la liste des payouts d'un utilisateur
 */
const getPayouts = async ({ walletId, pageNumber, pageCount }) => {
  try {
    const response = await axios.get(`${API_URL}/payouts/admin/payouts`, {
      params: {
        walletId,
        ...(pageNumber && { pageNumber }),
        ...(pageCount && { pageCount }),
        sortBy: 'createdDate',
        sortOrder: 'DESC',
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getPayouts = getPayouts;

export default api;
