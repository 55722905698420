<template>
  <section class="alerts">
    <p id="breadcrum">Alertes</p>

    <h2 id="title">Alertes</h2>

    <app-loader v-if="isLoading"/>
    <template v-else>
      <label>Type d'alerte</label>
      <app-select v-model="queries.type" :options="typeOptions()"></app-select>
      <label>Date de création</label>
      <app-datepicker v-model="queries.createdAt"></app-datepicker>
      <label>Identifiant client</label>
      <app-input v-model="queries.customerId"></app-input>
      <!-- <app-select></app-select> -->
      <template v-if="alerts.data && alerts.data.length > 0" >
        <app-table class="alerts-table" :headers="headers" :data="alerts.data" v-bind="tableConfig" @navigate="(e) => { getActions(e.offset); }">
          <template v-if="data.alertId" slot="alertId" slot-scope="{ data }">
            <p>{{ data.alertId }}</p>
          </template>

          <template v-if="data.zendeskId" slot="zendeskId" slot-scope="{ data }">
            <p>{{ data.zendeskId }}</p>
          </template>

          <template v-if="data.type" slot="type" slot-scope="{ data }">
            <p>{{ data.type }}</p>
          </template>

          <template slot="createdAt" slot-scope="{ data }">
            <p>{{ new Date(data.createdAt).toLocaleString() }}</p>
          </template>
        </app-table>
      </template>
      <div v-else class="no-results">Aucune alerte</div>
    </template>
  </section>
</template>

<script>
// import dayjs from 'dayjs';
import AppDatepicker from '../basics/components/AppDatepicker.vue';

import apiAlert from '../services/api/alert';
import EnumAlertType from '../services/enums/AlertType';

export default {
  components: { AppDatepicker },
  name: 'alerts',
  data() {
    return {
      alerts: null,
      EnumAlertType,

      isLoading: false,

      queries: {
        customerId: null || this.$route.query.customerId,
        type: null,
        limit: 20,
        offset: 0 || this.$route.query.offset,
        createdAt: null || this.$route.query.createdAt,
      },

      tableConfig: {
        showPagination: true,
        loading: false,
        limit: 0,
        offset: 0,
        count: 0,
      },

      headers: [
        { label: 'Id d\'alerte', key: 'alertId' },
        { label: 'Identifiant Zendesk', key: 'zendeskId' },
        { label: 'Type', key: 'alertType' },
        { label: 'Créée le', key: 'createdAt' },
      ],
    };
  },
  watch: {
    $route(to) {
      this.queries.offset = to.query.offset;
      this.queries.type = to.query.type;
      // this.queries.createdAt = to.query.createdAt;
      this.fetchData();
    },
    queries: {
      handler() {
        this.$router.replace({ name: this.$route.name, query: { ...this.queries, type: this.queries.type, createdAt: this.queries.createdAt ? this.queries.createdAt.toISOString() : this.queries.createdAt } }).catch(() => {});
      },
      deep: true,
    },
    // eslint-disable-next-line
    'queries.type'() {
      this.queries.offset = 0;
    },
    // eslint-disable-next-line
    'queries.createdAt'() {
      this.queries.offset = 0;
    },
    // eslint-disable-next-line
    'queries.customerId'() {
      this.queries.offset = 0;
    },
  },
  async created() {
    this.tableConfig.limit = this.queries.limit;
    this.tableConfig.offset = this.queries.offset;
    this.tableConfig.count = this.queries.count;

    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      try {
        // Get all alerts
        const response = await apiAlert.getAlerts({ ...this.queries });

        // Update variables for the AppTable component
        this.alerts = response;
        this.tableConfig.offset = response.metadata.offset;
        this.tableConfig.count = response.metadata.count;
      } catch (error) {
        //
      } finally {
        this.isLoading = false;
      }
    },
    // Function called when an arrow of the AppTable is pressed
    async getActions(offset) {
      // Condition to avoid reloading the same page when clicking multiple times on arrows
      if (offset !== Number(this.$route.query.offset)) {
        await this.$router.replace({ name: 'alerts', query: { offset: offset.toString() } });
      }
    },
    typeOptions() {
      return [
        ...Object.keys(EnumAlertType).map((type) => ({ name: type, label: EnumAlertType[type] })),
        { name: null, label: 'Tout' },
      ];
    },
  },
};
</script>

<style lang="sass">
.alerts
  display: flex
  flex-direction: column
  margin-bottom: 0
  padding-top: 1rem
  min-height: 100vh
  #title, .no-results
    padding-left: 4rem
  .alerts-table
    padding: 0 4rem 4rem 4rem
  #checkboxFilter
    margin-left: 4rem
  > label, input, .app-select
    margin: 0 4rem
    width: calc( 100% - 4rem*2 ) !important
  .app-pagination
    margin: 20px 0 auto auto
</style>
