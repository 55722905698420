<template>
  <span class="app-loader"><span>💶</span></span>
</template>

<script>
export default {
  props: {
    type: {
      default: 'button',
      validator: (val) => ['button', 'submit'].indexOf(val) !== -1,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    handleClick(evt) {
      this.$emit('click', evt);
    },
  },
};
</script>

<style lang="sass">
@keyframes rotating
  from
    transform: rotate(0deg)

  to
    transform: rotate(360deg)

.app-loader
  display: flex
  justify-content: center
  span
    padding: 3rem
    font-size: 3rem
    animation: rotating 0.6s linear infinite
    transform-origin: 50% 50%
</style>
