<template>
  <section class="actions">
    <p id="breadcrum">Actions</p>

    <h2 id="title">Actions</h2>

    <label>Identifiant client</label>
    <app-input v-model="customerId"></app-input>
    <app-table v-if="actions && actions.length !== 0" class="actions-table" :headers="headers" :data="actions" v-bind="tableConfig" @navigate="(e) => { getActions(e.offset); }">
      <template slot="actionId" slot-scope="{ data }">
        <router-link :to="`/actions/${data.actionId}`"><p class="link">{{ data.actionId }}</p></router-link>
      </template>

      <template slot="executedAt" slot-scope="{ data }">
        <p>{{new Date(data.executedAt).toLocaleString()}}</p>
      </template>

      <template slot="name" slot-scope="{ data }">
        <p>{{ActionType[data.name] || ActionType.UNPROCESSED}}</p>
      </template>

      <template slot="customerId" slot-scope="{ data }">
        <p>{{data.customer && data.customer[0] && data.customer[0].lastname}} {{data.customer && data.customer[0] && data.customer[0].firstname}}</p>
      </template>

      <template slot="authorId" slot-scope="{ data }">
        <p>{{data.author && data.author[0] && data.author[0].email}}</p>
      </template>
    </app-table>
    <div class="no-results" v-else-if="actions && actions.length === 0"></div>
    <app-loader v-else/>
  </section>
</template>

<script>
import apiAction from '../services/api/action';

import ActionType from '../services/enums/ActionType';

export default {
  name: 'actions',
  data() {
    return {
      actions: null,

      headers: [
        { label: 'Id de l\'action', key: 'actionId' },
        { label: 'Utilisateur', key: 'customerId' },
        { label: 'Auteur', key: 'authorId' },
        { label: 'Type', key: 'name' },
        { label: 'Adresse IP', key: 'customerIp' },
        { label: 'Effectué le', key: 'executedAt' },
      ],
      tableConfig: {
        showPagination: true,
        loading: false,
        limit: 20,
        offset: 0,
        count: 0,
      },
      customerId: null,

      ActionType,
    };
  },
  watch: {
    $route: 'fetchData',
    // eslint-disable-next-line
    customerId() {
      this.tableConfig.offset = 0;
      this.$router.replace({ name: this.$route.name, query: { ...this.queries, customerId: this.customerId } }).catch(() => {});
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      // Get all actions
      const response = await apiAction.getActions({
        limit: this.tableConfig.limit,
        offset: this.$route.query.offset,
        customerId: this.$route.query.customerId,
      });

      // Update variables for the AppTable component
      this.actions = response.data;
      this.tableConfig.offset = response.metadata.offset;
      this.tableConfig.count = response.metadata.count;
    },
    // Function called when an arrow of the AppTable is pressed
    async getActions(offset) {
      // Condition to avoid reloading the same page when clicking multiple times on arrows
      if (offset !== Number(this.$route.query.offset)) {
        await this.$router.replace({ name: 'actions', query: { offset: offset.toString(), customerId: this.$route.query.customerId } });
      }
    },
  },
};
</script>

<style lang="sass">
.actions
  margin-bottom: 0
  padding-top: 1rem
  min-height: 100vh
  #title, > label
    padding-left: 4rem
  .link
    color: #006D72
    font-weight: bold
  input, .app-select
    margin: 0 4rem
    width: calc( 100% - 4rem*2 ) !important
  .actions-table
    padding: 0 4rem 4rem 4rem
</style>
