<template>
  <section>
    <div v-if="payouts && !isLoading">
      <app-table
        v-if="!isLoading"
        :headers="headers"
        v-bind="tableConfig"
        :data="payouts"
        @navigate="(event) => { navigate(event) }"
      >
        <template slot="payout.payoutId" slot-scope="{ data }">
          <p>{{ data.payout.payoutId }}</p>
        </template>

        <template slot="payout.walletId" slot-scope="{ data }">
          <p>{{ data.payout.walletId }}</p>
        </template>

        <template slot="payout.payoutDate" slot-scope="{ data }">
          <p>{{ new Date(data.payout.payoutDate).toLocaleString() }}</p>
        </template>

        <template slot="payout.amount" slot-scope="{ data }">
          {{ data.payout.amount }}
          {{ data.payout.currency }}
        </template>

        <template slot="payout.payoutStatus" slot-scope="{ data }">
          <p>{{ data.payout.payoutStatus }}</p>
        </template>

        <template slot="empty-table" class="empty-table">
          <p>Aucun virement sortant de disponible</p>
        </template>

      </app-table>
    </div>

    <app-loader v-else />
  </section>
</template>

<script>
import ApiWallet from '@/services/api/wallet';
import ApiPayout from '@/services/api/payout';

export default {
  name: 'userVirements',

  data() {
    return {
      wallets: null,
      payouts: null,

      headers: [
        { label: 'PayoutId', key: 'payout.payoutId' },
        { label: 'WalletId', key: 'payout.walletId' },
        { label: 'Date Opération', key: 'payout.payoutDate' },
        { label: 'Montant', key: 'payout.amount' },
        { label: 'Statut', key: 'payout.payoutStatus' },
      ],

      pageNumber: 1,

      tableConfig: {
        showPagination: true,
        loading: false,
        limit: 20,
        offset: 0,
        count: 0,
      },

      isLoading: false,
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    async init() {
      await this.getWallet();
      await this.getPayouts();
    },

    // Récupération du wallet
    async getWallet() {
      this.wallets = await ApiWallet.getWallets({ userId: this.$route.params.userId });
    },

    // Récupération des virements sortants
    async getPayouts() {
      this.tableConfig.loading = true;

      try {
        // Récupération des payouts
        const response = await ApiPayout.getPayouts({
          walletId: this.wallets[0].walletId,
          pageNumber: this.pageNumber,
          pageCount: this.tableConfig.limit,
        });

        // Mise à jour des payouts
        this.payouts = response.data;

        // Mise à jour des de la config du tableau
        this.tableConfig.limit = response.metadata.limit;
        this.tableConfig.count = response.metadata.count;
      } catch (error) {
        this.payouts = [];
      } finally {
        this.tableConfig.loading = false;
      }
    },

    // Mise à jour de la config du tableau
    navigate(event) {
      // On vérifie si on navigue vers la page suivante ou la page précédente
      if (event.offset > this.tableConfig.offset && this.tableConfig.count - event.offset < this.tableConfig.limit) {
        this.pageNumber = Math.ceil(this.tableConfig.count / this.tableConfig.limit);
      } else if (event.offset > this.tableConfig.offset) {
        this.pageNumber += 1;
      } else if (event.offset === 0) {
        this.pageNumber = 1;
      } else {
        this.pageNumber -= 1;
      }

      this.tableConfig.offset = event.offset;
      this.getPayouts();
    },
  },
};
</script>
