<template>
  <section class="users">
    <p id="breadcrum">Utilisateurs</p>

    <h2 id="title">Utilisateurs</h2>
    <template v-if="users">
      <label for="readyForReviewStatus">État clôture du compte</label>
      <app-select id="readyForReviewStatus" v-model="closingAccountsFilter" @change="fetchData" :options="closingAccountsStatusList"/>

      <br/>

      <label for="readyForReviewStatus">État pré-validation</label>
      <app-select id="readyForReviewStatus" v-model="readyForReviewStatusFilter" :options="readyForReviewStatusList"/>

      <label for="search">Recherche</label>
      <app-input id="search" placeholder="Rechercher par le nom ou prénom" v-model="searchFilter"/>

      <app-table class="users-table" :headers="headers" :data="users" v-bind="tableConfig" @navigate="(e) => { getUsers(e.offset); }">
        <template slot="customerId" slot-scope="{ data }">
          <p class="link">{{ data.customerId }}</p>
        </template>

        <template slot="treezorUserId" slot-scope="{ data }">
          <router-link :to="`/users/${data.treezorUserId}/informations`"><p class="link">{{ data.treezorUserId }}</p></router-link>
        </template>

        <template slot="lastname" slot-scope="{ data }">
          <p v-if="data.lastname">{{ data.lastname }}</p>
          <p v-else-if="data.legalName">{{ data.legalName }}</p>
        </template>

        <template slot="birthdate" slot-scope="{ data }">
          <p v-if="data.birthdate">{{ new Date(data.birthdate).toLocaleDateString() }}</p>
          <p v-else-if="data.legalRegistrationDate">{{ new Date(data.legalRegistrationDate).toLocaleDateString() }}</p>
        </template>

        <template slot="livenessStatus" slot-scope="{ data }" v-if="data.type === 'INDIVIDUAL'">
          <p v-if="!data.livenessStatus">Pas initié</p>
          <p v-if="data.livenessStatus === 'INITIATED'">Initié</p>
          <p v-if="data.livenessStatus === 'PROCESSED'">Traité</p>
          <p v-if="data.livenessStatus === 'REFUSED'">Refusé</p>
        </template>

        <template slot="close" slot-scope="{ data }">
          <app-button ref="select" v-if="!data.treezorUserId" v-on:click="() => setSelectedUser(data)" small><p>Clôturer</p></app-button>
        </template>
      </app-table>
      <app-modal :show.sync="isVisible">
        <div id="selected">
          <div id="modalText">
            <h3 class="name">Cloturer le compte de :</h3>
            <p class="name" v-if="selectedUser.lastname && selectedUser.firstname">{{ selectedUser.lastname }} {{ selectedUser.firstname }}</p>
            <p class="name" v-else-if="selectedUser.legalName">{{ selectedUser.legalName }}</p>
            <p class="name" v-else>Unknown user</p>
            <p v-if="selectedUser.email">adresse email : {{selectedUser.email}}</p>
            <p class="error" v-if="errorMessage">{{errorMessage}}</p>
          </div>
          <div>
            <app-button ref="closeButton" @click="closeAccount(selectedUser.customerId)" :is-loading="loadingClose" class="button">Clôturer</app-button>
            <app-button ref="abort" @click="isVisible = false" class="button">Annuler</app-button>
          </div>
        </div>
      </app-modal>
    </template>
    <app-loader v-else/>
  </section>
</template>

<script>
import apiUser from '@/services/api/user';
import closeAccountUserType from '../services/enums/CloseAccountUserType';

export default {
  name: 'users',
  data() {
    return {
      users: null,
      selectedUser: { lastname: null, firstname: null, email: null },
      loadingClose: false,
      isVisible: false,
      errorMessage: null,

      headers: [
        { label: 'Identifiant Kalveen', key: 'customerId' },
        { label: 'Identifiant Treezor', key: 'treezorUserId' },
        { label: 'Nom', key: 'lastname' },
        { label: 'Prénom', key: 'firstname' },
        { label: 'Naissance', key: 'birthdate' },
        { label: 'Ville', key: 'placeOfBirth' },
        { label: 'Contrôle liveness', key: 'livenessStatus' },
        { label: 'Cloturer', key: 'close' },
      ],

      tableConfig: {
        showPagination: true,
        loading: false,
        limit: 10,
        offset: 0,
        count: 0,
      },

      closingAccountsFilter: 'OPENED',
      readyForReviewStatusFilter: null,
      searchFilter: null,

      closingAccountsStatusList: [
        { name: 'OPENED', label: 'Non clôturé' },
        { name: 'CLOSED', label: 'Clôturé' },
        { name: 'PENDING', label: 'En attente de clôture' },
        { name: null, label: 'Tous' },
      ],
      readyForReviewStatusList: [
        { name: null, label: 'Tous' },
        { name: 'REFUSED', label: 'Refusé' },
        { name: 'PENDING', label: 'En attente' },
        { name: 'VALIDATED', label: 'Validé' },
      ],
    };
  },
  watch: {
    $route: 'fetchData',
    closingAccountsFilter: 'fetchData',
    readyForReviewStatusFilter: 'fetchData',
    searchFilter() { this.debounceQuery(); },
  },
  mounted() {
    this.debounceQuery = this.$utils.debounce(this.fetchData, 400);
    this.fetchData();
  },
  methods: {
    async fetchData() {
      // Calculate the offset with the pageNumber route parameter and the row limit in the table
      const offset = this.$route.query.pageNumber * this.tableConfig.limit - this.tableConfig.limit;

      const response = await apiUser.getUsers({
        limit: this.tableConfig.limit,
        pageNumber: this.$route.query.pageNumber,
        ...(this.closingAccountsFilter && { closingStatus: this.closingAccountsFilter }),
        ...(this.readyForReviewStatusFilter && { readyForReviewStatus: this.readyForReviewStatusFilter }),
        ...(this.searchFilter && { search: this.searchFilter }),
      });

      // Update variables for the AppTable component
      this.users = response.data;
      this.tableConfig.offset = offset;
      this.tableConfig.count = response.metadata.count;
    },
    // Function called when an arrow of the AppTable is pressed
    async getUsers(offset) {
      // Calculate the pageNumber with the offset and the row limit in the table
      const pageNumber = offset / this.tableConfig.limit + 1;

      // Condition to avoid reloading the same page when clicking multiple times on arrows
      if (pageNumber !== Number(this.$route.query.pageNumber)) await this.$router.replace({ name: 'users', query: { pageNumber: pageNumber.toString() } });
    },
    // select the uzer which does not have any treezorUserID and print it at the end of the table
    async setSelectedUser(customer) {
      this.selectedUser = customer;
      this.isVisible = true;
    },
    // close selected user kalveen account
    async closeAccount(customerId) {
      try {
        this.loadingClose = true;
        this.errorMessage = null;
        await apiUser.closeAccount(customerId, closeAccountUserType.CUSTOMER);
        this.isVisible = false;
        this.fetchData();
      } catch (e) {
        this.errorMessage = 'Une erreur est survenue.';
      } finally {
        this.loadingClose = false;
      }
    },
  },
};
</script>

<style lang="sass">
.users
  margin-bottom: 0
  padding-top: 1rem
  min-height: 100vh
  #title
    padding-left: 4rem
  .link
    color: #006D72
    font-weight: bold
  > label, .app-select, input
    margin: 0 4rem
    width: calc( 100% - 4rem*2 ) !important
  .users-table
    padding: 0 4rem
  #checkboxFilterAll, #checkboxFilterClosed, #checkboxFilterPending
    margin-left: 4rem
#selected
  display: flex
  justify-content: center
  align-items: center
  height: 100%
  width: 100%
  align-content: center
  flex-direction: column
  #modalText
    padding-bottom: 10%
  #name
    height: 100%
  p, h3
    text-align: center
  .button
    display: flex
    justify-content: center
    align-content: center
    width: 100%
  .error
    color: red

</style>
