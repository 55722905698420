import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

// Get tax residences
const getTaxResidences = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/admin/tax-residences/${userId}`);
    return response.data.data[0] ? response.data.data[0] : null;
  } catch (error) {
    throw error;
  }
};

// Update taxResidence
const updateTaxResidence = async (taxResidenceId, taxResidence) => {
  try {
    const response = await axios.put(`${API_URL}/admin/tax-residences/${taxResidenceId}`, taxResidence);
    return response.data.data ? response.data.data : null;
  } catch (error) {
    throw error;
  }
};

// Create taxResidence
const createTaxResidence = async (taxResidence) => {
  try {
    const response = await axios.post(`${API_URL}/admin/tax-residences`, taxResidence);
    return response.data.data ? response.data.data : null;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getTaxResidences = getTaxResidences;
api.updateTaxResidence = updateTaxResidence;
api.createTaxResidence = createTaxResidence;

export default api;
