import Vue from 'vue';
import App from './App.vue';
import router from './router';

import Basics from './basics/index';
import utils from './services/utils/utils';

Vue.config.productionTip = false;

Vue.config.errorHandler = (err) => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.log(err);
  }
};

Vue.prototype.$utils = utils;

Vue.directive('capitalize', {
  componentUpdated: (el) => {
    const event = new Event('input');
    const tmpEl = el; tmpEl.value = utils.capitalize(tmpEl.value);
    tmpEl.dispatchEvent(event);
  },
});

Vue.use(Basics);

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
