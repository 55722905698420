import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

// Get documents
/**
 * Obtient pour un utilisateur une liste de frais existants avec leur action
 *
 * @param {string} treezorUserId
 * @param {number} pageNumber
 * @param {number} limit
 * @returns
 */
const getFeesWithTheirAction = async (treezorUserId, pageNumber, limit) => {
  try {
    const response = await axios.get(`${API_URL}/admin/fees`, {
      params: {
        treezorUserId,
        pageNumber,
        pageCount: limit,
      },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getFeesWithTheirAction = getFeesWithTheirAction;

export default api;
