import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

// Get actions
const getActions = async (params) => {
  try {
    const response = await axios.get(`${API_URL}/admin/actions`, {
      params: {
        limit: params.limit,
        offset: params.offset,
        executedFrom: params.executedFrom,
        executedTo: params.executedTo,
        customerId: params.customerId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Récupère les détails d'une action
 *
 * @param {*} actionId
 * @returns Action
 */
const getAction = async (actionId) => {
  try {
    const response = await axios.get(`${API_URL}/admin/actions/${actionId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Récupère les actions manuelles
 *
 * @param {string} treezorUserId
 * @returns ActionsWithPrice[]
 */
const getManualActionsWithPrice = async (treezorUserId) => {
  try {
    const response = await axios.get(`${API_URL}/admin/actions/billing/customer/${treezorUserId}`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Créer une action manuelle
 *
 * @param {string} treezorUserId
 * @param {string} actionName
 * @param {number} actionPrice
 * @returns Action
 */
const createManualAction = async (treezorUserId, actionName, actionPrice) => {
  try {
    const response = await axios.post(`${API_URL}/admin/actions/billing/customer/${treezorUserId}`, { name: actionName, price: actionPrice });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getActions = getActions;
api.getAction = getAction;
api.getManualActionsWithPrice = getManualActionsWithPrice;
api.createManualAction = createManualAction;

export default api;
