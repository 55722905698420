import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

const getPackageDetails = async (customerId) => {
  try {
    const response = await axios.get(`${API_URL}/admin/packages/${customerId}`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getPackageDetails = getPackageDetails;

export default api;
