var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"actions"},[_c('p',{attrs:{"id":"breadcrum"}},[_vm._v("Actions")]),_c('h2',{attrs:{"id":"title"}},[_vm._v("Actions")]),_c('label',[_vm._v("Identifiant client")]),_c('app-input',{model:{value:(_vm.customerId),callback:function ($$v) {_vm.customerId=$$v},expression:"customerId"}}),(_vm.actions && _vm.actions.length !== 0)?_c('app-table',_vm._b({staticClass:"actions-table",attrs:{"headers":_vm.headers,"data":_vm.actions},on:{"navigate":function (e) { _vm.getActions(e.offset); }},scopedSlots:_vm._u([{key:"actionId",fn:function(ref){
var data = ref.data;
return [_c('router-link',{attrs:{"to":("/actions/" + (data.actionId))}},[_c('p',{staticClass:"link"},[_vm._v(_vm._s(data.actionId))])])]}},{key:"executedAt",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(new Date(data.executedAt).toLocaleString()))])]}},{key:"name",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(_vm.ActionType[data.name] || _vm.ActionType.UNPROCESSED))])]}},{key:"customerId",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.customer && data.customer[0] && data.customer[0].lastname)+" "+_vm._s(data.customer && data.customer[0] && data.customer[0].firstname))])]}},{key:"authorId",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.author && data.author[0] && data.author[0].email))])]}}],null,false,1941328333)},'app-table',_vm.tableConfig,false)):(_vm.actions && _vm.actions.length === 0)?_c('div',{staticClass:"no-results"}):_c('app-loader')],1)}
var staticRenderFns = []

export { render, staticRenderFns }