<template>
  <div>
    <div>
      <div>
        <span>Etat de la demande d'analyse par l'utilisateur :</span>
        <span>{{ ReadyForReviewStatus[user.customer.readyForReviewStatus] }}</span>

        <div v-if="user.customer.readyForReviewStatus === 'PENDING'">
          <app-button ref="rejectAnalysis" @click="rejectAnalysis">Rejeter l'analyse</app-button>
        </div>
      </div>

      <p v-if="!user.customer.parentUserId">Virement initial :
        <span v-if="user.customer.initialPayin">✅ Effectué</span>
        <span v-else>❌ Non effectué</span>
      </p>

      <div v-if="(user.customer.type === 'INDIVIDUAL' && !user.customer.parentType) || (user.customer.type === 'INDIVIDUAL' && user.customer.parentType === 'LEADER')">Processus liveness :
        <span v-if="user.customer.livenessStatus === 'INITIATED'">🔄 En cours</span>
        <span v-else-if="user.customer.livenessStatus === 'REFUSED'">❌ Refusé</span>
        <span v-else-if="user.customer.livenessStatus === 'PROCESSED'">✅ Validé</span>

        <p v-if="user.customer.livenessComment">Commentaire liveness : {{ user.customer.livenessComment }}</p>

        <div v-if="user.customer.livenessStatus === 'PROCESSED'">
          <p><b>Demander la validation du liveness à Treezor</b></p>
          <p>Cliquer si un document de type "Résultat de l'enquête" et <br>des documents d'identité ayant pour type par exemple "Carte d'identité" n'existent pas pour cet utilisateur.<br>(Liste des documents affichée plus bas).</p>
          <app-button :disabled="user.customer.type === 'BUSINESS' || user.customer.parentType === 'SHAREHOLDER' || isValidateLivenessLoading || user.customer.livenessStatus === 'INITIATED'" :isLoading="isValidateLivenessLoading" ref="validateLiveness" @click="validateLiveness">
            <span v-if="user.customer.livenessStatus === 'INITIATED'">En attente de validation Ubble</span>
            <span v-else>Demander</span>
          </app-button>
        </div>
      </div>

      <app-button @click="newUbbleLink">
        <span>Envoyer un nouveau lien ubble</span>
      </app-button>

      <p>Etat validation des documents :
        <span v-if="user.customer.kycDocumentsValid === 'REFUSED'">❌ Au moins 1 document a été refusé, en attente de reupload de la part de l'utilisateur</span>
        <span v-else-if="user.customer.kycDocumentsValid === 'VALIDATED'">✅ Tous les documents ont été validés</span>
      </p>
    </div>

    <app-table v-if="documents && documents.length !== 0" :headers="headers" :data="documents" v-bind="tableConfig" @navigate="(e) => { getDocuments(e.offset); }">
      <template slot="documentTypeId" slot-scope="{ data }">
        <p>{{ DocumentType[data.documentTypeId] }}</p>
      </template>

      <template slot="statusV1" slot-scope="{ data }">
        <p>{{ DocumentTRZValidationStatus[data.statusV1] }}</p>
      </template>

      <template slot="statusCoreConnect" slot-scope="{ data }">
        <p>{{ DocumentKVNValidationStatus[data.statusCoreConnect] }}</p>
      </template>

      <template slot="createdAtV1" slot-scope="{ data }">
        <p v-if="data.createdAtV1">{{new Date(data.createdAtV1).toLocaleString()}}</p>
      </template>

      <template slot="createdAtCoreConnect" slot-scope="{ data }">
        <p v-if="data.createdAtCoreConnect">{{new Date(data.createdAtCoreConnect).toLocaleString()}}</p>
      </template>

      <template slot="documentCoreConnectId" slot-scope="{ data }">
        <button v-if="data.documentCoreConnectId" @click="openPreview(data.documentCoreConnectId)">Visualiser</button>
      </template>

      <template slot="buttons" slot-scope="{ data }">
        <button v-if="data.statusCoreConnect === 0" @click="triggerPreValidate(data.documentCoreConnectId)">PRE VALIDER</button>
      </template>
    </app-table>
    <div class="no-results" v-else-if="documents && documents.length === 0">Aucun document</div>
    <app-loader v-else/>

    <app-modal :show.sync="isModalVisible">
      <app-subtitle>Pré-validation</app-subtitle>
      <p>Ce document est-il valide ?</p>
      <input type="text" v-model="prevalidationComment" />

      <app-button @click="validate">VALIDER</app-button>
      <app-button @click="refuse">REFUSER</app-button>
    </app-modal>

    <app-message ref="errorModal"/>
  </div>
</template>

<script>
import apiUser from '@/services/api/user';
import apiDocument from '@/services/api/document';
import DocumentKVNValidationStatus from '@/services/enums/DocumentKVNValidationStatus';
import DocumentTRZValidationStatus from '@/services/enums/DocumentTRZValidationStatus';
import DocumentType from '@/services/enums/DocumentType';
import ReadyForReviewStatus from '@/services/enums/ReadyForReviewStatus';
import Swal from 'sweetalert2';

export default {
  name: 'userDocuments',
  data() {
    return {
      user: null,
      documents: null,

      headers: [
        { label: 'DocumentV1Id', key: 'documentV1Id' },
        { label: 'Visualisation', key: 'documentCoreConnectId' },
        { label: 'Type', key: 'documentTypeId', sizeClasses: 'small-2' },
        { label: 'UserId', key: 'userId' },
        { label: 'Statut KVN', key: 'statusCoreConnect' },
        { label: 'Statut TRZ', key: 'statusV1' },
        { label: 'Création KVN le', key: 'createdAtCoreConnect', sizeClasses: 'small-2' },
        { label: 'Création TRZ le', key: 'createdAtV1', sizeClasses: 'small-2' },
        { label: 'Actions', key: 'buttons' },
      ],
      tableConfig: {
        showPagination: false,
        loading: false,
        limit: 10,
        offset: 0,
        count: 0,
        algorithmPagination: true,
      },

      isModalVisible: false,
      documentToPreValidate: null,
      prevalidationComment: '',

      DocumentKVNValidationStatus,
      DocumentTRZValidationStatus,
      DocumentType,
      ReadyForReviewStatus,

      isValidateLivenessLoading: false,
    };
  },
  watch: {
    $route: 'fetchData',
  },
  async created() {
    this.user = await apiUser.getUser(this.$route.params.userId);
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      // Get all documents of an user
      const response = await apiDocument.getDocuments({
        customerId: this.user.customer.customerId,
      });

      this.documents = response;
    },
    async openPreview(documentId) {
      // Récupération de l'URL de preview
      const preview = await apiDocument.getPreviewUrl(documentId);

      // Ouverture de la preview dans un nouvel onglet
      window.open(preview.url, '_blank');
    },
    triggerPreValidate(documentId) {
      this.isModalVisible = true;
      this.documentToPreValidate = documentId;
    },
    async validate() {
      // Prévalidation positive du document
      await apiDocument.prevalidate(this.documentToPreValidate, 'VALIDATE', this.prevalidationComment);

      await this.finishPreValidation();
    },
    async refuse() {
      // Prévalidation négative du document
      await apiDocument.prevalidate(this.documentToPreValidate, 'REFUSE', this.prevalidationComment);

      await this.finishPreValidation();
    },
    async finishPreValidation() {
      // Reset des infos de pré-validation
      this.isModalVisible = false;
      this.documentToPreValidate = null;
      this.prevalidationComment = '';

      // Refresh des documents
      await this.fetchData();
    },
    async validateLiveness() {
      this.isValidateLivenessLoading = true;
      try {
        await apiUser.validateLiveness(this.user.customer.customerId);

        this.$refs.errorModal.show({
          title: 'Demande envoyée',
          text: 'Votre demande a bien été envoyée et est en cours de traitement.',
        });
      } catch (e) {
        this.$refs.errorModal.show({
          title: 'Error message',
          text: e.response.data.errors[0].message,
        });
      } finally {
        this.isValidateLivenessLoading = false;
      }
    },
    async rejectAnalysis() {
      try {
        await apiUser.changeReadyForReviewStatus(this.user.customer.customerId);

        this.$refs.errorModal.show({
          title: 'Confirmation message',
          text: 'The analysis has been refused',
        });
      } catch (e) {
        this.$refs.errorModal.show({
          title: 'Error message',
          text: e.response.data.errors[0].message,
        });
      }
    },
    async newUbbleLink() {
      Swal.fire({
        title: 'Êtes-vous sûr de creer un nouveau lien Ubble ? Cela engendrera des couts ',
        showDenyButton: true,
        confirmButtonText: 'Oui',
        denyButtonText: 'Non',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await apiUser.createKycLiveness(this.$route.params.userId, {}).then(() => {
            Swal.fire('Envoyé !', '', 'success');
          }).catch(() => {
            Swal.fire('Erreur', '', 'error');
          });
        }
      });
    },
  },
};
</script>
