const AlertType = {
  ALERT_WITHDRAWAL_FOREIGN_DAY: 'Retraits à l\'étranger/jour',
  ALERT_WITHDRAWAL_FOREIGN_WEEK: 'Retraits à l\'étranger/semaine',
  ALERT_WITHDRAWAL_FOREIGN_MONTH: 'Retraits à l\'étranger/mois',
  ALERT_WITHDRAWAL_OCCURENCE_DAY: 'Fréquence de retraits/jour',
  ALERT_CARD_TRANSACTION_FOREIGN_DAY: 'Paiements par carte à l\'étranger/jour',
  ALERT_CARD_TRANSACTION_FOREIGN_WEEK: 'Paiements par carte à l\'étranger/semaine',
  ALERT_CARD_TRANSACTION_FOREIGN_MONTH: 'Paiements par carte à l\'étranger/mois',
  ALERT_PAYIN_CHEQUE_AMOUNT_MONTH: 'Nombre de chèques encaissés/mois',
  ALERT_PAYOUT_SALARY_MONTH: 'Virements avec motif salaire/mois',
  ALERT_PAYIN_GREATER_TURN_OVER_MONTH: 'Nombre de virements entrants supérieur au chiffre d\'affaires/mois',
  ALERT_PAYOUT_EQUIVALENT_PAYIN: 'Virements sortants similaires aux virements entrants',
  ALERT_SAME_BENEFICIARY_PAYIN: 'Virements entrants d\'un même bénéficiaire',
  ALERT_SEPA_REJECT: 'Rejet de prélèvement SEPA',
  ALERT_FRAGMENTED_TRANSFERS: 'Emissions de virements sortants vers un même bénéficiaire',
  ALERT_ACCUMULATION_PAYOUT_DAY: 'Cumul des virements sortants/jour',
  ALERT_SAME_SOURCE_AMOUT_PAYIN: 'Virements entrants d\'un même bénéficiaire avec les mêmes montants',
  ALERT_TOO_MANY_BENEFICIARIES_PAYOUT_MONTH: 'Plusieurs virements sortants vers des bénéficiaires différents/mois',
  ALERT_PAYOUT_RISK_COUNTRY: 'Virements sortants vers un pays risqué',
  ALERT_CARD_TRANSACTION_SAME_MERCHANT: 'Paiements par carte vers un même marchand',
  ALERT_SAME_IP_DIFFERENT_USERS: 'Connexions à partir d\'une même adresse IP pour différents utilisateurs',
  ALERT_LOGIN_RISKY_COUNTRY_ORIGIN: 'Connexions à partir d\'un pays à risque',
  ALERT_LOGIN_FOREIGN_COUNTRY_ORIGIN: 'Connexions à partir d\'un pays étranger',
  ALERT_DIFFERENT_IP_SAME_USER: 'Connexions sur plusieurs IP par un même utilisateur',
  ALERT_PAYOUT_SAME_MERCHANT: 'Virements sortants vers un même marchand',
  ALERT_PAYOUT_RISKY_COUNTRY: 'Virement vers un pays à risque',
};

export default AlertType;
