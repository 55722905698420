<template>
  <div class="app-select" :class="{ open: isOpen }" tabindex="0" @focusout="isOpen = false">
    <div class="box" @click="isOpen = !isOpen">
      <slot v-if="options.find(o => o.name === value)">{{ options.find(o => o.name === value)['label'] }}</slot>
      <slot v-else>{{placeholder || 'Choisir'}}</slot>
    </div>
    <transition name="select">
      <ul v-if="isOpen" class="options">
        <li v-for="option in options" :key="option.name" @click="$emit('input', option.name), isOpen = false">{{ option.label }}</li>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Array, Number],
    // Format [{ name: 'lorem', label: 'Lorem Ipsum'}]
    options: [Array],
    placeholder: [String],
  },
  data() {
    return {
      isOpen: false,
    };
  },
};
</script>

<style lang="sass">
.app-select
  position: relative
  width: 100%
  background: white
  text-align: left
  border: 1px solid $light-color
  border-radius: $global-border-radius
  transition: all 0.2s ease-in-out
  cursor: pointer
  &:hover
    border-color: darken($light-color, 10%)
  &:focus
    outline: 0
    border-color: $main-color
  &.open
    border-color: $main-color
    border-radius: $global-border-radius $global-border-radius 0 0
    .box:after
      transform: rotate(45deg)
      border-color: $main-color

  .box
    position: relative
    padding: 0.7rem 1rem 0.6rem
    &:after
      content: ""
      position: absolute
      top: 50%
      right: 15px
      margin-top: -4px
      width: 8px
      height: 8px
      border: solid $light-color
      border-width: 0 2px 2px 0
      transform-origin: center
      transform: rotate(-45deg)
      transition: all 0.2s ease-in-out
      &:hover
        border-color: darken($light-color, 10%)

  .options
    position: absolute
    top: 100%
    left: -1px
    right: -1px
    margin: 0
    padding: 0
    list-style-type: none
    background: white
    border: 1px solid $main-color
    border-top-color: $light-color
    border-radius: 0 0 $global-border-radius
    overflow: hidden
    max-height: 215px
    overflow-y: auto
    z-index: 200
    li
      padding: 0.6rem 1rem
      transition: all 0.2s ease-in-out
      &:hover
        background: lighten($light-color, 5%)

.select-enter-active, .select-leave-active
  transition: all 0.25s ease-in-out

.select-enter, .select-leave-to
  opacity: 0
  transform: translateY(-10px)

</style>
