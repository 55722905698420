<template>
  <div>
    <app-table v-if="beneficiaries && beneficiaries.length !== 0" :headers="headers" :data="beneficiaries" v-bind="tableConfig" @navigate="(e) => { getBeneficiaries(e.offset); }">
      <template slot="name" slot-scope="{ data }">
        <p>{{ data.name.split(',')[0] }}</p>
      </template>

      <template slot="usableForSct" slot-scope="{ data }">
        <p v-if="data.usableForSct">Oui</p>
        <p v-else>Non</p>
      </template>

      <template slot="isActive" slot-scope="{ data }">
        <p v-if="data.isActive">Oui</p>
        <p v-else>Non</p>
      </template>
    </app-table>

    <div class="no-results" v-else-if="beneficiaries && beneficiaries.length === 0">Aucun bénéficiaire</div>
    <app-loader v-else/>
  </div>
</template>

<script>
import apiBeneficiaries from '@/services/api/beneficiaries';

export default {
  name: 'userBeneficiaires',
  data() {
    return {
      beneficiaries: null,
      headers: [
        { label: 'Identifiant', key: 'id' },
        { label: 'Nom', key: 'name' },
        { label: 'IBAN (encrypted)', key: 'iban', sizeClasses: 'small-4' },
        { label: 'BIC', key: 'bic' },
        { label: 'Utilisable pour SCT', key: 'usableForSct' },
        { label: 'Actif', key: 'isActive' },
      ],
      tableConfig: {
        showPagination: true,
        loading: false,
        limit: 10,
        offset: 0,
        count: 0,
        algorithmPagination: true,
      },
    };
  },
  watch: {
    $route: 'fetchData',
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      // Calculate the offset with the pageNumber route parameter and the row limit in the table
      const offset = this.$route.query.pageNumber * this.tableConfig.limit - this.tableConfig.limit;

      // Get all beneficiaries of an user
      const response = await apiBeneficiaries.getBeneficiaries({
        limit: this.tableConfig.limit,
        pageNumber: this.$route.query.pageNumber,
        userId: this.$route.params.userId,
      });

      // Update variables for the AppTable component
      this.beneficiaries = response;
      this.tableConfig.offset = offset;
      this.tableConfig.count = response.length;

      // Checking if there is another page after this
      if (response.length === this.tableConfig.limit) {
        const nextPage = await apiBeneficiaries.getBeneficiaries({
          limit: this.tableConfig.limit,
          pageNumber: parseInt(this.$route.query.pageNumber, 10) + 1,
          userId: this.$route.params.userId,
        });
        if (nextPage.length === 0) this.tableConfig.count = 0; // Disable the right arrow if the next page is empty
      }
    },
    // Function called when an arrow of the AppTable is pressed
    async getBeneficiaries(offset) {
      // Calculate the pageNumber with the offset and the row limit in the table
      const pageNumber = offset / this.tableConfig.limit + 1;

      // Condition to avoid reloading the same page when clicking multiple times on arrows
      if (pageNumber !== Number(this.$route.query.pageNumber)) await this.$router.replace({ name: 'user-beneficiaires', query: { pageNumber: pageNumber.toString() } });
    },
  },
};
</script>
