import Vue from 'vue';
import Router from 'vue-router';
import Login from './views/Login.vue';
import Register from './views/Register.vue';
import ForgotPassword from './views/ForgotPassword.vue';
import Activate from './views/Activate.vue';
import Users from './views/Users.vue';
import User from './views/User.vue';
import Transactions from './views/Transactions.vue';
import UserInformations from './views/UserInformations.vue';
import UserTransactions from './views/UserTransactions.vue';
import UserVirements from './views/UserVirements.vue';
import UserBeneficiaires from './views/UserBeneficiaires.vue';
import UserCartes from './views/UserCartes.vue';
import UserDocuments from './views/UserDocuments.vue';
import UserUtilisateursLies from './views/UserUtilisateursLies.vue';
import UserActions from './views/UserActions.vue';
import UserFacturations from './views/UserFacturations.vue';
import UserGCS from './views/UserGCS.vue';
import Actions from './views/Actions.vue';
import ActionInformations from './views/Action/ActionInformations.vue';
import Alerts from './views/Alerts.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      redirect: { name: 'login' },
    },
    {
      path: '/',
      redirect: { name: 'login' },
    },
    {
      path: '/users',
      name: 'users',
      component: Users,
      meta: { requiresAuth: true },
    },
    {
      path: '/users/:userId',
      component: User,
      children: [
        {
          path: 'informations',
          name: 'user-informations',
          component: UserInformations,
          meta: { requiresAuth: true },
        },
        {
          path: 'transactions',
          name: 'user-transactions',
          component: UserTransactions,
          meta: { requiresAuth: true },
        },
        {
          path: 'virements',
          name: 'user-virements',
          component: UserVirements,
          meta: { requiresAuth: true },
        },
        {
          path: 'beneficiaires',
          name: 'user-beneficiaires',
          component: UserBeneficiaires,
          meta: { requiresAuth: true },
        },
        {
          path: 'cartes',
          name: 'user-cartes',
          component: UserCartes,
          meta: { requiresAuth: true },
        },
        {
          path: 'documents',
          name: 'user-documents',
          component: UserDocuments,
          meta: { requiresAuth: true },
        },
        {
          path: 'utilisateurs-lies',
          name: 'user-utilisateurs-lies',
          component: UserUtilisateursLies,
          meta: { requiresAuth: true },
        },
        {
          path: 'actions',
          name: 'user-actions',
          component: UserActions,
          meta: { requiresAuth: true },
        },
        {
          path: 'facturations',
          name: 'user-facturations',
          component: UserFacturations,
          meta: { requiresAuth: true },
        },
        {
          path: 'gcs',
          name: 'user-gcs',
          component: UserGCS,
          meta: { requiresAuth: true },
        },
      ],
    },
    {
      path: '/connexion',
      name: 'login',
      component: Login,
    },
    {
      path: '/inscription',
      name: 'register',
      component: Register,
    },
    {
      path: '/mot-de-passe-perdu',
      name: 'forgot-password',
      component: ForgotPassword,
    },
    {
      path: '/activate-account',
      name: 'activate-account',
      component: Activate,
    },
    {
      path: '/transactions',
      name: 'transactions',
      component: Transactions,
      meta: { requiresAuth: true },
    },
    {
      path: '/actions',
      name: 'actions',
      component: Actions,
      meta: { requiresAuth: true },
    },
    {
      path: '/actions/:actionId',
      name: 'actions-informations',
      component: ActionInformations,
      meta: { requiresAuth: true },
    },
    {
      path: '/alerts',
      name: 'alerts',
      component: Alerts,
      meta: { requiresAuth: true },
    },
  ],
});
