<template>
  <div>
    <div v-if="wallets">
      <div class="grid-x grid-margin-x list-wallet">
        <div v-for="wallet in wallets" v-bind:key="wallet.walletId" class="card-wallet cell medium-4">
          <h1>{{ wallet.eventName }}</h1>
          <p><b>Identifiant :</b> {{ wallet.walletId }}</p>
          <p><b>Statut :</b> {{ wallet.walletStatus }}</p>
          <p><b>Balance :</b> {{wallet.solde}}</p>
          <p><b>Balance autorisée :</b> {{wallet.authorizedBalance}}</p>
        </div>
      </div>
      <hr class="separator">

      <app-table v-if="transactions && transactions.length !== 0" :headers="headers" :data="transactions" v-bind="tableConfig" @navigate="(e) => { fetchData(e.cursor); }">
        <template slot="date.creation" slot-scope="{ data }">
          <p>{{ new Date(data.date.creation).toLocaleString() }}</p>
        </template>

        <template v-if="data.date.settlement" slot="date.settlement" slot-scope="{ data }">
          <p>{{ new Date(data.date.settlement).toLocaleString() }}</p>
        </template>

        <template slot="amount" slot-scope="{ data }">
          {{ data.amount.amount.toString().substr(0, data.amount.amount.toString().length - 2) }}.{{ data.amount.amount.toString().substr(data.amount.amount.toString().length - 2) }}
          {{ data.amount.currency }}
        </template>

        <template slot="infos" slot-scope="{ data }">
          <p>{{ data.label.split('(')[0] }} [{{ data.direction }}]</p>
        </template>

        <template slot="infos2" slot-scope="{ data }">
          <p>{{ data.messageToUser }} {{ data.dbtrIban }} {{ data.ibanBic }} {{ data.ibanFullname }} {{ data.instant === true ? "[INSTANT]" : ""}}</p>
        </template>

        <template slot="payinCreate" slot-scope="{ data }">
          <a v-if="data.createActionId" :href="'/actions/' + data.createActionId">Lien</a>
        </template>

        <template slot="payinUpdate" slot-scope="{ data }">
          <a v-if="data.updateActionId" :href="'/actions/' + data.updateActionId">Lien</a>
        </template>

      </app-table>
      <div v-else-if="transactions && transactions.length === 0" class="no-results">Aucune opération n'a été effectuée</div>
      <app-loader v-else/>
    </div>
    <app-loader v-else/>
  </div>
</template>

<script>
import apiWallet from '@/services/api/wallet';
import apiTransaction from '@/services/api/transaction';

export default {
  name: 'userTransactions',
  data() {
    return {
      wallets: null,
      transactions: null,

      headers: [
        { label: 'Identifiant', key: 'objectId' },
        { label: 'WalletId', key: 'walletId', sizeClasses: 'small-1' },
        { label: 'Date Opération', key: 'date.creation' },
        { label: 'Date effective', key: 'date.settlement' },
        { label: 'Montant', key: 'amount' },
        { label: 'Statut', key: 'status' },
        { label: 'Infos', key: 'infos', sizeClasses: 'small-2' },
        { label: 'Infos', key: 'infos2', sizeClasses: 'small-3' },
        { label: 'Webhook Create', key: 'payinCreate' },
        { label: 'Webhook Update', key: 'payinUpdate' },
      ],

      tableConfig: {
        showPagination: true,
        loading: false,
        cursorPagination: true,
        cursor: null,
      },
    };
  },
  watch: {
    $route: 'fetchData',
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData(cursor) {
      // Get all wallets of an user
      this.wallets = await apiWallet.getWallets({ userId: this.$route.params.userId });

      // Get all transactions for every wallets
      const promises = this.wallets.map(async () => {
        const transactions = await apiTransaction.getTransactionsByUserId({
          treezorUserId: this.$route.params.userId,
          cursor,
        });
        // Update variables for the AppTable component
        this.tableConfig.cursor = transactions.cursor;

        return transactions.data;
      });
      // Sort transactions by date
      this.transactions = ((await Promise.all(promises)).flat());
    },
  },
};
</script>

<style lang="sass">
.list-wallet
  margin-top: 3rem
.card-wallet
  padding: 1rem 2rem
  background-color: #fff
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15)
  border-radius: 16px
  margin-bottom: 2rem
.separator
  border: 1px solid lightgray
</style>
