var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"users"},[_c('p',{attrs:{"id":"breadcrum"}},[_vm._v("Utilisateurs")]),_c('h2',{attrs:{"id":"title"}},[_vm._v("Utilisateurs")]),(_vm.users)?[_c('label',{attrs:{"for":"readyForReviewStatus"}},[_vm._v("État clôture du compte")]),_c('app-select',{attrs:{"id":"readyForReviewStatus","options":_vm.closingAccountsStatusList},on:{"change":_vm.fetchData},model:{value:(_vm.closingAccountsFilter),callback:function ($$v) {_vm.closingAccountsFilter=$$v},expression:"closingAccountsFilter"}}),_c('br'),_c('label',{attrs:{"for":"readyForReviewStatus"}},[_vm._v("État pré-validation")]),_c('app-select',{attrs:{"id":"readyForReviewStatus","options":_vm.readyForReviewStatusList},model:{value:(_vm.readyForReviewStatusFilter),callback:function ($$v) {_vm.readyForReviewStatusFilter=$$v},expression:"readyForReviewStatusFilter"}}),_c('label',{attrs:{"for":"search"}},[_vm._v("Recherche")]),_c('app-input',{attrs:{"id":"search","placeholder":"Rechercher par le nom ou prénom"},model:{value:(_vm.searchFilter),callback:function ($$v) {_vm.searchFilter=$$v},expression:"searchFilter"}}),_c('app-table',_vm._b({staticClass:"users-table",attrs:{"headers":_vm.headers,"data":_vm.users},on:{"navigate":function (e) { _vm.getUsers(e.offset); }},scopedSlots:_vm._u([{key:"customerId",fn:function(ref){
var data = ref.data;
return [_c('p',{staticClass:"link"},[_vm._v(_vm._s(data.customerId))])]}},{key:"treezorUserId",fn:function(ref){
var data = ref.data;
return [_c('router-link',{attrs:{"to":("/users/" + (data.treezorUserId) + "/informations")}},[_c('p',{staticClass:"link"},[_vm._v(_vm._s(data.treezorUserId))])])]}},{key:"lastname",fn:function(ref){
var data = ref.data;
return [(data.lastname)?_c('p',[_vm._v(_vm._s(data.lastname))]):(data.legalName)?_c('p',[_vm._v(_vm._s(data.legalName))]):_vm._e()]}},{key:"birthdate",fn:function(ref){
var data = ref.data;
return [(data.birthdate)?_c('p',[_vm._v(_vm._s(new Date(data.birthdate).toLocaleDateString()))]):(data.legalRegistrationDate)?_c('p',[_vm._v(_vm._s(new Date(data.legalRegistrationDate).toLocaleDateString()))]):_vm._e()]}},{key:"livenessStatus",fn:function(ref){
var data = ref.data;
return (data.type === 'INDIVIDUAL')?[(!data.livenessStatus)?_c('p',[_vm._v("Pas initié")]):_vm._e(),(data.livenessStatus === 'INITIATED')?_c('p',[_vm._v("Initié")]):_vm._e(),(data.livenessStatus === 'PROCESSED')?_c('p',[_vm._v("Traité")]):_vm._e(),(data.livenessStatus === 'REFUSED')?_c('p',[_vm._v("Refusé")]):_vm._e()]:undefined}},{key:"close",fn:function(ref){
var data = ref.data;
return [(!data.treezorUserId)?_c('app-button',{ref:"select",attrs:{"small":""},on:{"click":function () { return _vm.setSelectedUser(data); }}},[_c('p',[_vm._v("Clôturer")])]):_vm._e()]}}],null,true)},'app-table',_vm.tableConfig,false)),_c('app-modal',{attrs:{"show":_vm.isVisible},on:{"update:show":function($event){_vm.isVisible=$event}}},[_c('div',{attrs:{"id":"selected"}},[_c('div',{attrs:{"id":"modalText"}},[_c('h3',{staticClass:"name"},[_vm._v("Cloturer le compte de :")]),(_vm.selectedUser.lastname && _vm.selectedUser.firstname)?_c('p',{staticClass:"name"},[_vm._v(_vm._s(_vm.selectedUser.lastname)+" "+_vm._s(_vm.selectedUser.firstname))]):(_vm.selectedUser.legalName)?_c('p',{staticClass:"name"},[_vm._v(_vm._s(_vm.selectedUser.legalName))]):_c('p',{staticClass:"name"},[_vm._v("Unknown user")]),(_vm.selectedUser.email)?_c('p',[_vm._v("adresse email : "+_vm._s(_vm.selectedUser.email))]):_vm._e(),(_vm.errorMessage)?_c('p',{staticClass:"error"},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e()]),_c('div',[_c('app-button',{ref:"closeButton",staticClass:"button",attrs:{"is-loading":_vm.loadingClose},on:{"click":function($event){return _vm.closeAccount(_vm.selectedUser.customerId)}}},[_vm._v("Clôturer")]),_c('app-button',{ref:"abort",staticClass:"button",on:{"click":function($event){_vm.isVisible = false}}},[_vm._v("Annuler")])],1)])])]:_c('app-loader')],2)}
var staticRenderFns = []

export { render, staticRenderFns }