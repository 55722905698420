<template>
  <section id="app">
    <div class="app-container">
      <navigation></navigation>
      <div class="view">
        <router-view/>
      </div>
    </div>

    <!-- Global basics components integration -->
    <app-message/>
    <app-notifications/>
  </section>
</template>

<script>
import Navigation from '@/components/Navigation.vue';

export default {
  name: 'app',
  components: {
    Navigation,
  },
};
</script>
<style lang="sass">
@import "@/assets/sass/app.sass"

#app
  .app-container
    .view
      padding-left: calc(200px)
      background-color: $light-blue
</style>
