<!--
*************
*** PROPS ***
*************
* tabs: [Object] - liste d'objets décrivant les boutons onglets à afficher
  @prop {string} title : Titre à afficher pour l'onglet
  @prop {string} key : clé à utiliser pour différencier les onglets
  @prop {boolean} disabled : Si vrai, l'onglet ne sera pas accessible (le bouton sera toujours visible mais rien ne se passera en cliquant dessus)
*************
*** SLOTS ***
*************
Doc sur les scope-slots
- https://fr.vuejs.org/v2/guide/components-slots.html#Slots-avec-portee
- https://www.digitalocean.com/community/tutorials/vuejs-scoped-component-slots
Pour chaque onglet, vous indiquer le contenu à afficher lorsque l'onglet est actif avec le slot:<template>
  * CLE-CHAMP : le nom dépend du champ 'key' indiqué pour l'onglet
**************
*** EVENTS ***
**************
  * changetab : émit à chaque changement d'onglet. Il contient la clé de l'onglet activé.
-->

<template>
  <div class="app-cardtabs">
    <div class="tab-titles grid-x">
      <span
        v-for="(tab, index) in tabs"
        :key="tab.key"
        class="title cell shrink"
        :class="{ active: activeTab === tab.key, disabled: tab.disabled }"
        @click="changeTab(index)"
      >
          {{ tab.title }}
      </span>
    </div>
    <div
      v-for="tab in tabs"
      :key="`${tab.key}-content`"
    >
      <div v-show="activeTab === tab.key" class="tab-container">
        <slot :name="tab.key"></slot>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'app-cardtabs',
  props: {
    // Format: [{ title: 'Lorem Ispum', key: 'lorem, disabled: true }]
    tabs: [Array],
  },
  data() {
    return {
      activeTab: this.tabs[0].key,
    };
  },
  methods: {
    changeTab(index) {
      if (this.tabs[index].disabled) {
        return;
      }
      this.activeTab = this.tabs[index].key;
      this.$emit('changetab', this.activeTab);
    },
  },
};
</script>

<style lang="sass">
.app-cardtabs
  .tab-titles
    padding-left: 4rem
    .title
      margin: 0 0.05rem
      padding: 0.75rem 2rem
      background-color: $light-grey
      color: $medium-grey
      cursor: pointer
      border-top-right-radius: 8px
      border-top-left-radius: 8px
      font-weight: bold
    .title.active
      background-color: white
      color: black
      //color: $primary-font
  .tab-container
    margin: 0
    padding: 2rem 4rem
    background-color: white
    //border-radius: $global-border-radius
    //border-top-left-radius: 0
    //box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1)
    text-align: left
</style>
