<template>
  <section class="register">
    <div class="grid-container">
      <app-subtitle>Register</app-subtitle>

      <form ref="register" @submit.prevent="register(user)">
        <div>
          <app-label required>Email</app-label>
          <app-input autocomplete="email" type="email" placeholder="email" required v-model="user.email"/>
        </div>
        <div>
          <app-label required>Password</app-label>
          <app-input autocomplete="new-password" type="password" placeholder="mot de passe" required  v-model="user.password"/>
        </div>
        <div>
          <app-button type="submit">Register</app-button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import api from '@/services/api/member';

export default {
  name: 'register',
  data() {
    return {
      user: {
        email: '',
        password: '',
      },
    };
  },
  methods: {
    async register(user) {
      if (this.$refs.register.checkValidity()) {
        try {
          await api.register(user);
          this.$message.show({
            title: 'Inscription réussie',
            text: 'Votre inscription est effectuée. Vous allez prochainement recevoir un email pour activer votre compte.',
            confirmText: 'Ok',
            hasCancel: false,
          });
        } catch (error) {
          this.$message.show({
            title: 'Erreur',
            text: 'Impossible de vous inscrire',
            confirmText: 'Ok',
            hasCancel: false,
          });

          throw error;
        }
      } else {
        this.$refs.register.reportValidity();
      }
    },
  },
};
</script>

<style lang="sass">
.register
  padding: 1rem
  text-align: left
  form > div
    padding: 1rem 0
</style>
