import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

const getGcsUser = async (params) => {
  try {
    const response = await axios.get(`${API_URL}/gcs`, {
      params: {
        ...(params?.userId && { userId: params.userId }),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const createGcsUser = async (params) => {
  try {
    const response = await axios.post(`${API_URL}/gcs/create/${params.userId}`, {
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const deleteGcsUser = async (params) => {
  try {
    const response = await axios.post(`${API_URL}/gcs/delete/${params.userId}`, {
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getGcsUser = getGcsUser;
api.createGcsUser = createGcsUser;
api.deleteGcsUser = deleteGcsUser;

export default api;
