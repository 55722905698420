<template>
  <div class="user-gcs">

    <div class="grid-x grid-margin-x list-wallet">
      <div v-if="gcsUser" class="card-wallet cell medium-4">
        <h1>Profil chez GCS</h1>
        <p><b>Identifiant :</b> {{ gcsUser.Id }}</p>
        <p><b>Prénom :</b> {{ gcsUser.FirstName }}</p>
        <p><b>Nom :</b> {{ gcsUser.LastName }}</p>
        <p><b>Salutation :</b> {{ gcsUser.Salutation == 1 ? "Mr."  : "Ms."}}</p>
        <p><b>Sexe :</b> {{ gcsUser.Sexe__pc }}</p>
        <p><b>Date de naissance :</b> {{ gcsUser.PersonBirthdate }}</p>
        <p><b>Code postal :</b> {{ gcsUser.PersonMailingPostalCode }}</p>
        <p><b>Ville :</b> {{ gcsUser.PersonMailingCity }}</p>
        <p><b>Adresse :</b> {{ gcsUser.PersonMailingStreet }}</p>
        <p><b>Pays :</b> {{ gcsUser.PersonMailingCountry }}</p>
        <p><b>Mail :</b> {{ gcsUser.EmailAddress1__pc }}</p>
        <p><b>Tél :</b> {{ gcsUser.PersonMobilePhone }}</p>
        <p><b>Date de début d'adhésion au programme :</b> {{ gcsUser.Date_adhesion_programme__pc }}</p>
        <p><b>Date de fin d'adhésion au programme:</b> {{ gcsUser.Fin_engagement_programme__pc }}</p>
        <p><b>Langue :</b> {{ gcsUser.LangueEchange__pc }}</p>
        <p><b>Nationalité :</b> {{ gcsUser.Nationalite__pc }}</p>
      </div>
      <div v-else class="card-wallet cell medium-4">
          <p>Aucun Profil GCS </p>
      </div>
    </div>
    <hr class="separator">
    <app-button class="updateUser" @click="createUser">Creer un profil chez Generali</app-button>
    <app-button class="updateUser" @click="deleteUser">Fin d'adhesion chez Generali</app-button>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import apiGcs from '../services/api/gcs';

export default {
  name: 'userFacturations',
  data() {
    return {
      gcsUser: null,
    };
  },
  watch: {
    $route: 'fetchData',
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.gcsUser = await apiGcs.getGcsUser({ userId: this.$route.params.userId });
    },
    async createUser() {
      Swal.fire({
        title: 'Êtes-vous sûr de creer un profil chez Generali ? Cela engendrera des couts ',
        showDenyButton: true,
        confirmButtonText: 'Oui',
        denyButtonText: 'Non',
      }).then(async (result) => {
        if (result.isConfirmed) {
          apiGcs.createGcsUser({ userId: this.$route.params.userId }).then(() => {
            window.location.reload();
          }).catch(() => {
            Swal.fire('Erreur', '', 'error');
          });
        } else if (result.isDenied) {
          Swal.fire('Aucun Profil de crée ', '', 'info');
        }
      });
    },
    async deleteUser() {
      Swal.fire({
        title: 'Êtes-vous sûr de supprimer un profil chez Generali ? ',
        showDenyButton: true,
        confirmButtonText: 'Oui',
        denyButtonText: 'Non',
      }).then(async (result) => {
        if (result.isConfirmed) {
          apiGcs.deleteGcsUser({ userId: this.$route.params.userId }).then(() => {
            window.location.reload();
          }).catch(() => {
            Swal.fire('Erreur', '', 'error');
          });
        } else if (result.isDenied) {
          Swal.fire('Aucun Profil de supprimer ', '', 'info');
        }
      });
    },
  },
};
</script>

<style lang="sass">
.list-wallet
  margin-top: 3rem
.card-wallet
  padding: 1rem 2rem
  background-color: #fff
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15)
  border-radius: 16px
  margin-bottom: 2rem
.separator
  border: 1px solid lightgray
</style>
