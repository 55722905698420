<template>
  <section class="forgot-password">
    <h1>This is an forgot-password page</h1>
  </section>
</template>

<script>
export default {
  name: 'forgot-password',
};
</script>

<style lang="sass">
.forgot-password
  padding: 1rem
</style>
