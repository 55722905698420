import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

/**
 * Get alerts
 * @param {string} customerId
 * @param {AlertType} type
 * @param {number} limit
 * @param {number} offset
 * @param {Date} createdAt
 * @returns
 */
const getAlerts = async ({
  customerId, type, limit, offset, createdAt,
}) => {
  try {
    const response = await axios.get(`${API_URL}/admin/alerts`, {
      params: {
        ...(customerId && { customerId }),
        ...(type && { type }),
        ...(limit && { limit }),
        ...(offset && { offset }),
        ...(createdAt && { createdAt }),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getAlerts = getAlerts;

export default api;
