<template>
  <div>
    <app-button class="usurpeUser" v-if="!user.customer.parentUserId" @click="usurpeUser">Se connecter en tant que...</app-button>
    <p class="close-btn" v-if="iframe" @click="closeIframe">❌</p>
    <div class="grid-x grid-margin-x" v-if="user">
      <div class="cell medium-6 table-column">

        <h2>Général</h2>
        <div class="form-item">
          <label for="UserUid">Identifiant Interne de l'utilisateur</label>
          <app-input id="UserUid" v-model="user.customer.customerId" :disabled="true"/>
        </div>
        <div class="form-item">
          <label for="treezorUserId">Identifiant Treezor de l'utilisateur</label>
          <app-input id="treezorUserId" v-model="user.customer.treezorUserId" :disabled="true"/>
        </div>
        <div class="form-item">
          <label for="subscription">Abonnement</label>
          <app-input id="subscription" v-if="packageDetails !== null" v-model="packageDetails.name" :disabled="true"/>
          <app-input id="subscription" v-else value='Aucune offre souscrite' :disabled="true"/>
        </div>
        <div class="form-item">
          <label for="type">Type d'utilisateur</label>
          <app-input id="type" v-model="user.customer.type" :disabled="true"/>
        </div>
        <div class="form-item">
          <label for="kycLevel">KYC Level</label>
          <app-input id="kycLevel" v-model="user.treezorUser.kycLevel" :disabled="true"/>
        </div>
        <div class="form-item">
          <label for="kycReview">KYC Review</label>
          <app-input id="kycReview" v-model="user.treezorUser.kycReview" :disabled="true"/>
        </div>
        <div class="form-item">
          <label for="createdAt">Date de création</label>
          <app-input id="createdAt" :value="new Date(user.treezorUser.createdDate).toLocaleString()" :disabled="true"/>
        </div>
        <div class="form-item">
          <label for="updatedAt">Date de modification</label>
          <app-input id="updatedAt" :value="new Date(user.treezorUser.modifiedDate).toLocaleString()" :disabled="true"/>
        </div>
        <div class="form-item">
          <label for="isActive">Statut du compte</label>
          <app-input id="isActive" :value="user.customer.isActive ? 'Actif' : 'Inactif'" :disabled="true"/>
        </div>
        <div class="form-item">
          <label for="isActive">Adresse mail</label>
          <app-input id="isActive" v-model="user.customer.email"/>
        </div>

        <h2>Informations entreprise</h2>
        <div class="form-item">
          <label for="legalRegistrationNumber">SIRET</label>
          <app-input id="legalRegistrationNumber" v-model="user.customer.legalRegistrationNumber"/>
        </div>
        <div class="form-item">
          <label for="legalName">Raison sociale</label>
          <app-input id="legalName" v-model="user.customer.legalName"/>
        </div>
        <div class="form-item">
          <label for="legalForm">Forme juridique</label>
          <app-select id="legalForm" v-model="user.customer.legalForm" :options="legalForms"/>
        </div>
        <div class="form-item">
          <label for="entityType">Type d'entité</label>
          <app-input id="entityType" v-model="user.customer.entityType" disabled/>
        </div>
        <div class="form-item">
          <label for="legalSector">Secteur d'activité</label>
          <app-select id="legalSector" v-model="user.customer.legalSector" :options="legalSectors"/>
        </div>
        <div class="form-item">
          <label for="legalTvaNumber">Numéro de TVA</label>
          <app-input id="legalTvaNumber" v-model="user.customer.legalTvaNumber"/>
        </div>

        <h2>Chiffre entreprise</h2>
        <div class="form-item">
          <label for="legalRegistrationDate">Date d'immatriculation</label>
          <app-datepicker id="legalRegistrationDate" v-model="user.customer.legalRegistrationDate" isUtc />
        </div>
        <div class="form-item">
          <label for="legalShareCapital">Capital social</label>
          <app-input id="legalShareCapital" v-model="user.customer.legalShareCapital"/>
        </div>
        <div class="form-item">
          <label for="legalAnnualTurnOver">Chiffre d'affaire annuel en k€</label>
          <app-select id="legalAnnualTurnOver" v-model="user.customer.legalAnnualTurnOver" :options="legalAnnualTurnOverRanges"/>
        </div>
        <div class="form-item">
          <label for="legalNetIncomeRange">Bénéfice net annuel en k€</label>
          <app-select id="legalNetIncomeRange" v-model="user.customer.legalNetIncomeRange" :options="legalNetIncomeRanges"/>
        </div>
        <div class="form-item">
          <label for="legalNumberOfEmployeeRange">Tranche de salariés</label>
          <app-select id="legalNumberOfEmployeeRange" v-model="user.customer.legalNumberOfEmployeeRange" :options="legalNumberOfEmployeeRanges"/>
        </div>

        <h2>Embargo entreprise</h2>
        <div class="form-item">
          <label>Votre société a-t-elle une activité commerciale hors UE ?</label>
          <br/>
          <app-radio id="activityOutsideEuTrue" v-model="user.customer.activityOutsideEu" :value="true"/>
          <label for="activityOutsideEuTrue">Oui</label>
          <app-radio id="activityOutsideEuFalse" v-model="user.customer.activityOutsideEu" :value="false"/>
          <label for="activityOutsideEuFalse">Non</label>
        </div>
        <div class="form-item">
          <label>Votre société, ses succursales, ses filiales, joint-ventures, ses dirigeants ou ses employés sont-ils visés par des mesures de Sanctions Économiques ?</label>
          <br/>
          <app-radio id="economicSanctionsTrue" v-model="user.customer.economicSanctions" :value="true"/>
          <label for="economicSanctionsTrue">Oui</label>
          <app-radio id="economicSanctionsFalse" v-model="user.customer.economicSanctions" :value="false"/>
          <label for="economicSanctionsFalse">Non</label>
        </div>
        <div class="form-item">
          <label>Votre société, ses succursales, ses filiales ou ses joint-ventures sont-ils physiquement présents dans, ou opèrent-ils depuis, des pays soumis à des sanctions étendues ?</label>
          <br/>
          <app-radio id="residentCountriesSanctionsTrue" v-model="user.customer.residentCountriesSanctions" :value="true" />
          <label for="residentCountriesSanctionsTrue">Oui</label>
          <app-radio id="residentCountriesSanctionsFalse" v-model="user.customer.residentCountriesSanctions" :value="false" />
          <label for="residentCountriesSanctionsFalse">Non</label>
        </div>
        <div class="form-item">
          <label>Après un examen minutieux et à votre connaissance, votre société, ses succursales, ses filiales ou ses joint-ventures sont-ils impliqués dans les opérations, des investissements, des activités ou toutes autres transactions impliquant ou profitant directement ou indirectement à (i) des pays soumis à des sanctions étendues ou à (ii) toute personne ou entité visée par des Sanctions ("Personnes Sanctionnées") ?</label>
          <br/>
          <app-radio id="involvedSanctionTrue" v-model="user.customer.involvedSanctions" :value="true"/>
          <label for="involvedSanctionTrue">Oui</label>
          <app-radio id="involvedSanctionFalse" v-model="user.customer.involvedSanctions" :value="false"/>
          <label for="involvedSanctionFalse">Non</label>
        </div>

        <app-button class="updateUser" @click="updateUser">Modification</app-button>
      </div>

      <div class="cell medium-6 table-column">
        <h2>Informations personnelles</h2>
        <div class="form-item">
          <label>Vous êtes ?</label>
          <br/>
          <app-radio id="civilityM" v-model="user.customer.civility" value="M"/>
          <label for="civilityM">Homme</label>
          <app-radio id="civilityMME" v-model="user.customer.civility" value="MME"/>
          <label for="civilityMME">Femme</label>
        </div>
        <div class="form-item">
          <label for="lastname">Nom</label>
          <app-input id="lastname" v-capitalize v-model="user.customer.lastname"/>
        </div>
        <div class="form-item">
          <label for="firstname">Prénom</label>
          <app-input id="firstname" v-capitalize v-model="user.customer.firstname"/>
        </div>

        <h2>Naissance</h2>
        <div class="form-item">
          <label for="birthdate">Date de naissance</label>
          <app-datepicker id="birthdate" v-model="user.customer.birthdate" isUtc />
        </div>
        <div class="form-item">
          <label for="placeOfBirth">Lieu de naissance</label>
          <app-input id="placeOfBirth" v-model="user.customer.placeOfBirth"/>
        </div>
        <div class="form-item">
          <label for="placeBirthCountry">Pays de naissance</label>
          <app-select id="placeBirthCountry" v-model="user.customer.placeBirthCountry" :options="countries"/>

        </div>
        <div class="form-item">
          <label for="nationality">Nationalité</label>
          <app-select id="nationality" v-model="user.customer.nationality" :options="countries"/> <!-- Obligé de mettre la nationalité sous le format ISO 3166-1 alpha-2 car sinon Treezor ne prend pas en compte la demande de KYC review-->
        </div>

        <h2>Patrimoine</h2>
        <div class="form-item">
          <label for="occupation">Emploi</label>
          <app-input id="occupation" v-model="user.customer.occupation"/>
        </div>
        <div class="form-item">
          <label for="incomeRange">Revenu</label>
          <app-select id="incomeRange" v-model="user.customer.incomeRange" :options="incomeRanges"/>
        </div>
        <div class="form-item">
          <label for="personalAssets">Patrimoine</label>
          <app-select id="personalAssets" v-model="user.customer.personalAssets" :options="personalAssetsRanges"/>
        </div>

        <h2>Résidence fiscale</h2>
        <div class="form-item">
          <label>Je certifie que je suis uniquement un résident fiscal français</label>
          <br/>
          <app-radio id="isFrenchFiscalResidentTrue" v-model="user.customer.isFrenchFiscalResident" :value="true"/>
          <label for="isFrenchFiscalResidentTrue">Oui</label>
          <app-radio id="isFrenchFiscalResidentFalse" v-model="user.customer.isFrenchFiscalResident" :value="false"/>
          <label for="isFrenchFiscalResidentFalse">Non</label>
        </div>
        <div class="form-item">
          <label>Je certifie que je suis une US Person</label>
          <br/>
          <app-radio id="specifiedUSPersonTrue" v-model="user.customer.specifiedUsPerson" :value="true"/>
          <label for="specifiedUSPersonTrue">Oui</label>
          <app-radio id="specifiedUSPersonFalse" v-model="user.customer.specifiedUsPerson" :value="false"/>
          <label for="specifiedUSPersonFalse">Non</label>
        </div>
        <div class="form-item">
          <label for="taxResidenceCountry">Pays de résidence fiscale</label>
          <app-select id="taxResidenceCountry" v-model="taxResidence.country" :options="countries"/>
        </div>
        <div class="form-item">
          <label for="taxPayerId">Numéro d'identification fiscale</label>
          <app-input id="taxPayerId" v-model="taxResidence.taxPayerId"/>
        </div>

        <h2>Adresse</h2>
        <div class="form-item">
          <label for="streetNumber">Numéro</label>
          <app-input id="address" v-model="userAddress.streetNumber"/>
        </div>
        <div class="form-item">
          <label for="streetNumber">Libellé de la voie</label>
          <app-input id="route" v-model="userAddress.route"/>
        </div>
        <div class="form-item">
          <label for="postalCode">Code postal</label>
          <app-input id="postalCode" v-model="userAddress.postalCode"/>
        </div>
        <div class="form-item">
          <label for="locality">Ville</label>
          <app-input id="locality" v-model="userAddress.locality"/>
        </div>
        <div class="form-item">
          <label for="country">Pays</label>
          <app-select id="country" v-model="userAddress.administrativeAreaLevel1" :options="countries"/>
        </div>
        <div class="form-item">
          <label for="phone">Numéro de téléphone</label>
          <app-input id="phone" v-model="user.customer.phone"/>
        </div>

        <h2>Actionnaire</h2>
        <div class="form-item">
          <label for="sharesNumber">Pourcentage de participation</label>
          <app-input id="sharesNumber" v-model="user.customer.sharesNumber"/>
        </div>
      </div>

    </div>
    <app-loader v-else/>
    <app-message ref="errorModal"/>
  </div>
</template>

<script>
import apiUser from '@/services/api/user';
import apiTaxResidence from '@/services/api/tax-residence';
import apiPackage from '@/services/api/package';

import legalSectors from '../../../commons/legalSectors.json';
import legalForms from '../../../commons/legalForms.json';
import legalAnnualTurnOverRanges from '../../../commons/legalAnnualTurnOverRanges.json';
import legalNetIncomeRanges from '../../../commons/legalNetIncomeRanges.json';
import legalNumberOfEmployeeRanges from '../../../commons/legalNumberOfEmployeeRanges.json';
import incomeRanges from '../../../commons/incomeRanges.json';
import personalAssetsRanges from '../../../commons/personalAssetsRanges.json';
import countries from '../../../commons/countries.json';
import companyEntityTypes from '../../../commons/companyEntityTypes.json';

export default {
  name: 'userInformations',
  data() {
    return {
      user: null,
      userAddress: {
        route: null,
        country: null,
        locality: null,
        postalCode: null,
        streetNumber: null,
        administrativeAreaLevel1: null,
      },
      taxResidence: {
        id: null,
        country: null,
        taxPayerId: null,
      },
      staticUser: null,
      staticUserAddress: null,
      staticTaxResidence: null,

      legalSectors,
      legalForms,
      legalAnnualTurnOverRanges,
      legalNetIncomeRanges,
      legalNumberOfEmployeeRanges,
      incomeRanges,
      personalAssetsRanges,
      countries,
      companyEntityTypes,

      packageDetails: null,
      iframe: false,
    };
  },
  watch: {
    $route: 'fetchData',
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.user = await apiUser.getUser(this.$route.params.userId);
      if (this.user.customer.address) this.userAddress = this.user.customer.address;

      this.packageDetails = await apiPackage.getPackageDetails(this.user.customer.customerId);

      const taxResidence = await apiTaxResidence.getTaxResidences(this.$route.params.userId);
      if (taxResidence) this.taxResidence = taxResidence;

      // Deep copy of objects to compare updated values
      this.staticUser = JSON.parse(JSON.stringify(this.user.customer));
      this.staticUserAddress = JSON.parse(JSON.stringify(this.userAddress));
      this.staticTaxResidence = JSON.parse(JSON.stringify(this.taxResidence));
    },
    async closeIframe() {
      document.getElementById('iframe').remove();
      this.iframe = false;
    },
    async usurpeUser() {
      const ifrm = document.createElement('iframe');
      ifrm.setAttribute('src', 'https://app.kalveen.com/');
      ifrm.style.width = '100%';
      ifrm.style.zIndex = 100;
      ifrm.style.position = 'fixed';
      ifrm.style.left = 0;
      ifrm.style.top = 0;
      ifrm.style.minHeight = '100vh';
      ifrm.style.display = 'none';
      ifrm.id = 'iframe';
      document.body.appendChild(ifrm);
      const token = await apiUser.getJWTImpersonate(this.$route.params.userId);

      await setTimeout(() => {
        ifrm.contentWindow.postMessage({
          action: 'erase',
          value: '',
        }, '*');
      }, 3000);

      await setTimeout(() => {
        ifrm.contentWindow.postMessage({
          action: 'token',
          value: token,
        }, '*');
      }, 3000);

      await setTimeout(() => {
        ifrm.contentWindow.postMessage({
          action: 'reload',
          value: '',
        }, '*');
      }, 3000);
      ifrm.style.display = 'block';
      this.iframe = true;
    },
    async updateUser() {
      const errors = [];
      const user = {};
      const taxResidence = {};

      Object.keys(this.user.customer).forEach((field) => {
        // Checking if it's address field because it is a composed field so it doesn't work the same
        if (field !== 'address') {
          // Checking if the field has changed to update it
          if (this.user.customer[field] !== this.staticUser[field]) {
            user[field] = this.user.customer[field];
          }
        } else {
          // Creating an address object to add it to the user after
          const addressTemp = {};
          Object.keys(this.userAddress).forEach((addressField) => {
            // Checking if the field has changed to update it
            if (this.userAddress[addressField] !== this.staticUserAddress[addressField]) {
              addressTemp[addressField] = this.userAddress[addressField];
            }
          });
          // If at least one field has changed, address object is added to user to update address in Kalveen and Treezor
          if (Object.keys(addressTemp).length !== 0) {
            // Complete country field in addressTemp by searching with administrativeAreaLevel1 field in countries json
            if (addressTemp.administrativeAreaLevel1) addressTemp.country = (countries.filter((country) => country.name === addressTemp.administrativeAreaLevel1))[0].label;

            // We combine old address object with new because otherwise, when we update address in Kalveen DB, only updated field are kept and all others are deleted
            user.address = Object.assign(this.staticUserAddress, addressTemp);
          }
        }
      });
      Object.keys(this.taxResidence).forEach((field) => {
        // Checking if the field has changed to update it
        if (this.taxResidence[field] !== this.staticTaxResidence[field]) {
          taxResidence[field] = this.taxResidence[field];
        }
      });

      // Checking if taxResidence has been changed or created to update it in Treezor
      if (Object.keys(taxResidence).length !== 0) {
        try {
          // Checking if the taxResidence id exists to update the current, otherwise creates a new taxResidence
          if (this.taxResidence.id) {
            await apiTaxResidence.updateTaxResidence(this.taxResidence.id, taxResidence);
          } else {
            await apiTaxResidence.createTaxResidence({
              userId: this.$route.params.userId,
              ...taxResidence,
            });
          }
        } catch (e) {
          errors.push(e.response.data.message);
        }
      }
      // Checking if user has been changed to update it in Kalveen and Treezor
      if (Object.keys(user).length !== 0) {
        try {
          await apiUser.updateUser(this.$route.params.userId, user);
        } catch (e) {
          errors.push(e.response.data.message);
        }
      }
      // Checking errors to show confirmation or error modal
      if (errors.length === 0) {
        this.$refs.errorModal.show({
          title: 'Confirmation message',
          text: 'The profile has been updated !',
        });
      } else {
        this.$refs.errorModal.show({
          title: 'Error message',
          text: errors.toString(),
        });
      }
    },
  },
};
</script>

<style lang="sass">
.form-item
  @include form-item
.app-input:disabled
  background-color: lightgray
.table-column
  padding-right: 4rem
  h2:not(:first-child)
    padding-top: 3rem
  .updateUser
    margin: 4rem 0
.close-btn
  position: fixed
  top: 0
  right: 10px
  z-index: 101
  color: red
</style>
