<template>
  <section class="action-informations">
    <div v-if="action">
      <h2>Action </h2>

      <div class="general-container">
        <div class="form-item">
          <label for="actionId">Identifiant de l'action</label>
          <app-input id="actionId" :value="action.actionId" :disabled="true"/>
        </div>

        <div class="form-item">
          <label for="customerId">Identifiant de l'utilisateur concerné par l'action</label>
          <app-input id="customerId" :value="action.customerId" :disabled="true"/>
          <app-button @click="openURL($router.resolve({ path: `/users/${action.customer[0].treezor_user_id}/informations` }))" :disabled="!action.customer || !action.customer[0] || !action.customer[0].treezor_user_id">Profil</app-button>
        </div>

        <div class="form-item">
          <label for="authorId">Identifiant de l'administrateur à l'origine de l'action (vide si c'est une création automatique)</label>
          <app-input id="authorId" :value="action.authorId" :disabled="true"/>
        </div>

        <div class="form-item">
          <label for="name">Type d'action</label>
          <app-input id="name" :value="ActionType[action.name] || ActionType.UNPROCESSED" :disabled="true"/>
        </div>

        <div class="form-item">
          <label for="executedAt">Exécuté le</label>
          <app-input id="executedAt" :value="new Date(action.executedAt).toLocaleString()" :disabled="true"/>
        </div>

        <div class="form-item">
          <label for="customerIp">Ip de l'utilisateur (vide si c'est une création automatique)</label>
          <app-input id="customerIp" :value="action.customerIp" :disabled="true"/>
        </div>

        <div class="form-item">
          <label for="targetEntity">Ressource concernée</label>
          <app-input id="targetEntity" :value="ActionTargetEntity[action.targetEntity]" :disabled="true"/>
        </div>

        <div class="form-item">
          <label for="metadata">Metadata de la requête effectuée</label>
          <textarea name="Text1" cols="40" rows="5" :value="JSON.stringify(action.metadata)" :disabled="true"></textarea>
        </div>

        <div class="form-item">
          <label for="webhookId">Identifiant du webhook Treezor (vide si création du côté Kalveen)</label>
          <app-input id="webhookId" :value="action.webhookId" :disabled="true"/>
        </div>

        <div class="form-item">
          <label for="createdAt">Créée le</label>
          <app-input id="createdAt" :value="new Date(action.createdAt).toLocaleString()" :disabled="true"/>
        </div>

        <div class="form-item">
          <label for="updatedAt">Dernière mise à jour le</label>
          <app-input id="updatedAt" :value="new Date(action.updatedAt).toLocaleString()" :disabled="true"/>
        </div>

        <div class="form-item">
          <label for="deletedAt">Supprimée le (vide si l'action n'a pas été supprimée)</label>
          <app-input id="deletedAt" :value="action.deletedAt ? new Date(action.deletedAt).toLocaleString() : ''" :disabled="true"/>
        </div>
      </div>
    </div>

    <div v-else>
      <h2>Pas d'action trouvée</h2>
    </div>
  </section>
</template>

<script>
import apiAction from '@/services/api/action';

import ActionType from '../../services/enums/ActionType';
import ActionTargetEntity from '../../services/enums/ActionTargetEntity';

export default {
  name: 'action-informations',
  data() {
    return {
      action: null,

      ActionType,
      ActionTargetEntity,
    };
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const tmpAction = await apiAction.getAction(this.$route.params.actionId);

        this.action = tmpAction.data;
      } catch (err) {
        this.action = null;
      }
    },
    async openURL(route) {
      window.open(route.href);
    },
  },
};
</script>

<style lang="sass">
.action-informations
  padding: 2rem 0 0 0
  h2
    padding: 0 4rem
  .general-container
    margin-top: 0.75rem
    padding: 0.05rem 4rem 2rem 4rem
    background-color: white
    min-height: 100vh
    text-align: left
    .form-item
      @include form-item
      textarea
        width: 100%
        background-color: lightgray
        border-radius: 4px
        border: 1px solid #ddc65e
</style>
