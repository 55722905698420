var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"alerts"},[_c('p',{attrs:{"id":"breadcrum"}},[_vm._v("Alertes")]),_c('h2',{attrs:{"id":"title"}},[_vm._v("Alertes")]),(_vm.isLoading)?_c('app-loader'):[_c('label',[_vm._v("Type d'alerte")]),_c('app-select',{attrs:{"options":_vm.typeOptions()},model:{value:(_vm.queries.type),callback:function ($$v) {_vm.$set(_vm.queries, "type", $$v)},expression:"queries.type"}}),_c('label',[_vm._v("Date de création")]),_c('app-datepicker',{model:{value:(_vm.queries.createdAt),callback:function ($$v) {_vm.$set(_vm.queries, "createdAt", $$v)},expression:"queries.createdAt"}}),_c('label',[_vm._v("Identifiant client")]),_c('app-input',{model:{value:(_vm.queries.customerId),callback:function ($$v) {_vm.$set(_vm.queries, "customerId", $$v)},expression:"queries.customerId"}}),(_vm.alerts.data && _vm.alerts.data.length > 0)?[_c('app-table',_vm._b({staticClass:"alerts-table",attrs:{"headers":_vm.headers,"data":_vm.alerts.data},on:{"navigate":function (e) { _vm.getActions(e.offset); }},scopedSlots:_vm._u([{key:"alertId",fn:function(ref){
var data = ref.data;
return (data.alertId)?[_c('p',[_vm._v(_vm._s(data.alertId))])]:undefined}},{key:"zendeskId",fn:function(ref){
var data = ref.data;
return (data.zendeskId)?[_c('p',[_vm._v(_vm._s(data.zendeskId))])]:undefined}},{key:"type",fn:function(ref){
var data = ref.data;
return (data.type)?[_c('p',[_vm._v(_vm._s(data.type))])]:undefined}},{key:"createdAt",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(new Date(data.createdAt).toLocaleString()))])]}}],null,true)},'app-table',_vm.tableConfig,false))]:_c('div',{staticClass:"no-results"},[_vm._v("Aucune alerte")])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }