const DocumentType = {
  2: 'Dossier de police',
  4: 'Enregistrement de l\'entreprise',
  6: 'CV',
  7: 'Déclaration sous serment',
  8: 'Chiffres d\'affaires',
  9: 'Carte d\'identité',
  11: 'Relevé d\'identité bancaire',
  12: 'Justificatif de domicile',
  13: 'Facture de téléphone portable',
  14: 'Facture, autre que la facture de téléphone mobile',
  15: 'Permis de séjour',
  16: 'Permis de conduire',
  17: 'Passeport',
  18: 'Procuration accordée à un employé',
  19: 'Papier officiel d\'enregistrement de l\'entreprise',
  20: 'Certificat fiscal officiel',
  21: 'Avis de paiement du salarié',
  22: 'Relevé bancaire de l\'utilisateur',
  23: 'Statut juridique de l\'entreprise',
  24: 'Déclaration fiscale',
  25: 'Déclaration d\'exonération',
  26: 'Résultat de l\'enquête',
  27: 'Carte d\'assurance maladie',
};

export default DocumentType;
