import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

// Get cards
const getCards = async (params) => {
  try {
    const response = await axios.get(`${API_URL}/admin/cards`, {
      params: {
        treezorUserId: params.treezorUserId,
        isPhysical: params.isPhysical,
        pageNumber: params.pageNumber,
        pageCount: params.pageCount,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

// Get card
const getCard = async (cardId) => {
  try {
    const response = await axios.get(`${API_URL}/trz/v1/cards/${cardId}`);
    return response.data.cards[0] ? response.data.cards[0] : null;
  } catch (error) {
    throw error;
  }
};

// Unlock card PIN
const unlockPin = async (cardId) => {
  try {
    await axios.post(`${API_URL}/cards/${cardId}/unblock-PIN`);
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getCards = getCards;
api.getCard = getCard;
api.unlockPin = unlockPin;

export default api;
