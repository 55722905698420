import axios from 'axios';
import * as dayjs from 'dayjs';

const API_URL = process.env.VUE_APP_API_URL;

// Get transactions of a wallet
const getTransactionsByWalletId = async (params) => {
  try {
    const dateFrom = dayjs().year(2000).format();
    const dateTo = dayjs().format();

    const response = await axios.get(`${API_URL}/trz/core-connect/operations`, {
      params: {
        walletId: params.walletId,
        dateFrom,
        dateTo,
        ...(params?.cursor && { cursor: params.cursor }),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getTransactionsByUserId = async (params) => {
  try {
    const dateFrom = dayjs().year(2000).format();
    const dateTo = dayjs().format();

    const response = await axios.get(`${API_URL}/admin/operations/${params.treezorUserId}`, {
      params: {
        dateFrom,
        dateTo,
        ...(params?.cursor && { cursor: params.cursor }),
      },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const getAllTransactions = async (params) => {
  try {
    const response = await axios.get(`${API_URL}/admin/operations`, {
      params: {
        ...(params?.onlyRiskyTransaction && { onlyRiskyTransaction: params.onlyRiskyTransaction }),
        ...(params?.limit && { limit: params.limit }),
        ...(params?.offset && { offset: params.offset }),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getTransactionsByWalletId = getTransactionsByWalletId;
api.getAllTransactions = getAllTransactions;
api.getTransactionsByUserId = getTransactionsByUserId;

export default api;
