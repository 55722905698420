var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"transactions"},[_c('p',{attrs:{"id":"breadcrum"}},[_vm._v("Transactions")]),_c('h2',{attrs:{"id":"title"}},[_vm._v("Transactions")]),_c('div',[(_vm.transactions && _vm.transactions.length !== 0)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checked),expression:"checked"}],attrs:{"type":"checkbox","id":"checkboxFilter"},domProps:{"checked":Array.isArray(_vm.checked)?_vm._i(_vm.checked,null)>-1:(_vm.checked)},on:{"change":[function($event){var $$a=_vm.checked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checked=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checked=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checked=$$c}},_vm.changeFilter]}}):_vm._e(),_c('label',{attrs:{"for":"checkboxFilter"}},[_vm._v("Only risky transactions (>= 500€)")]),(_vm.transactions && _vm.transactions.length !== 0)?_c('app-table',_vm._b({staticClass:"transactions-table",attrs:{"headers":_vm.headers,"data":_vm.transactions},on:{"navigate":function (e) { _vm.getTransactions(e.offset); }},scopedSlots:_vm._u([{key:"date.creation",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(new Date(data.date.creation).toLocaleString()))])]}},{key:"date.settlement",fn:function(ref){
var data = ref.data;
return (data.date.settlement)?[_c('p',[_vm._v(_vm._s(new Date(data.date.settlement).toLocaleString()))])]:undefined}},{key:"amount",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.amount.amount.toString().substr(0, data.amount.amount.toString().length - 2))+". "+_vm._s(data.amount.amount.toString().substr(data.amount.amount.toString().length - 2))+" "+_vm._s(data.amount.currency)+" ")]}},{key:"infos",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.label.split('(')[0]))]),_c('p',[_vm._v(_vm._s(data.direction))])]}}],null,true)},'app-table',_vm.tableConfig,false)):(_vm.transactions && _vm.transactions.length === 0)?_c('div',[_vm._v("No transactions")]):_c('app-loader')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }