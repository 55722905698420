<template>
  <div class="user-facturations">
    <app-button class="create-button" @click="toggleIsCreateActionEnabled">Créer une facturation</app-button>

    <app-button class="create-button" @click="toggleIsCreateDiscountEnabled">Créer une remise</app-button>

    <transition name="fade">
      <div v-if="isCreateActionEnabled && !isCreateDiscountEnabled" class="create-content">
        <app-select :value="createActionSelected" :options="optionSelectComputed" @input="(value) => createActionSelected = value" placeholder="Choisir une facturation"></app-select>
        <app-button @click="createNonAutoAction" :disabled="createLoading">Valider</app-button>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="!isCreateActionEnabled && isCreateDiscountEnabled" class="create-content">
        <app-select :value="createDiscountSelected" :options="discountsSelectComputed" @input="(value) => createDiscountSelected = value" placeholder="Choisir une remise"></app-select>
        <app-button @click="createNonAutoDiscount" :disabled="createLoading">Valider</app-button>
      </div>
    </transition>

    <app-table v-if="fees && fees.length !== 0" :headers="headers" :data="fees" v-bind="tableConfig" @navigate="(e) => { getExistingFeeWithTheirAction(e.offset); }">
      <template slot="feeId" slot-scope="{ data }">
        <p class="fee">Frais</p>
        <p>{{ data.feeId }}</p>
      </template>

      <template slot="feePrice" slot-scope="{ data }">
        <p>{{ data.price / 100 }}€</p>
      </template>

      <template slot="feeCreatedAt" slot-scope="{ data }">
        <p>{{ dayjs(data.createdAt).format('DD-MM-YYYY') }}</p>
      </template>

      <template slot="actionId" slot-scope="{ data }">
        <p class="action">Action</p>
        <p class="action-id">{{ data.action.actionId }}</p>
      </template>

      <template slot="actionName" slot-scope="{ data }">
        <p>{{ data.action.name }}</p>
      </template>

      <template slot="actionCreatedAt" slot-scope="{ data }">
        <p>{{ dayjs(data.action.createdAt).format('DD-MM-YYYY') }}</p>
      </template>
    </app-table>

    <div class="no-results" v-else-if="fees && fees.length === 0">Aucun frais</div>
    <app-loader v-else/>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import apiFee from '../services/api/fee';
import apiAction from '../services/api/action';
import EnumCreateAction from '../../../commons/billing.json';

export default {
  name: 'userFacturations',
  data() {
    return {
      fees: null,
      headers: [
        { label: 'Identifiant', key: 'feeId' },
        { label: 'Prix', key: 'feePrice' },
        { label: 'Créé le', key: 'feeCreatedAt' },
        { label: 'Identifiant', key: 'actionId' },
        { label: 'Nom', key: 'actionName' },
        { label: 'Créé le', key: 'actionCreatedAt' },
      ],
      tableConfig: {
        showPagination: true,
        loading: false,
        limit: 10,
        offset: 0,
        count: 0,
        algorithmPagination: true,
      },
      isCreateActionEnabled: false,
      isCreateDiscountEnabled: false,
      createActionSelected: null,
      createDiscountSelected: null,
      createLoading: false,
      dayjs,
      manualActions: [],
      manualDiscounts: [],
    };
  },
  watch: {
    $route: 'fetchData',
  },
  computed: {
    optionSelectComputed() {
      return this.manualActions.map((action) => {
        const tmpAction = EnumCreateAction.find((createAction) => createAction.name === action.name);
        const format = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', signDisplay: 'never' });
        const tmpPrice = format.format(action.price / 100);

        return {
          name: action.name,
          label: `${tmpAction.label} (${tmpPrice})`,
        };
      });
    },
    discountsSelectComputed() {
      return this.manualDiscounts.map((action) => {
        const tmpAction = EnumCreateAction.find((createAction) => createAction.name === action.name);
        const format = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', signDisplay: 'never' });
        const tmpPrice = format.format(action.price / 100);

        return {
          name: action.name,
          label: `${tmpAction.label} (${tmpPrice})`,
        };
      });
    },
  },
  mounted() {
    this.fetchData();
    this.getManualActionsWithPrice();
  },
  methods: {
    async fetchData() {
      // Calculate the offset with the pageNumber route parameter and the row limit in the table
      const offset = this.$route.query.pageNumber * this.tableConfig.limit - this.tableConfig.limit;

      // Get all fees of an user
      const response = await apiFee.getFeesWithTheirAction(
        this.$route.params.userId,
        this.$route.query.pageNumber,
        this.tableConfig.limit,
      );

      // Update variables for the AppTable component
      this.fees = response;
      this.tableConfig.offset = offset;
      this.tableConfig.count = response.length;

      // Checking if there is another page after this
      if (response.length === this.tableConfig.limit) {
        const nextPage = await apiFee.getFeesWithTheirAction(
          this.$route.params.userId,
          parseInt(this.$route.query.pageNumber, 10) + 1,
          this.tableConfig.limit,
        );
        if (nextPage.length === 0) this.tableConfig.count = 0; // Disable the right arrow if the next page is empty
      }
    },
    async getExistingFeeWithTheirAction(offset) {
      // Calculate the pageNumber with the offset and the row limit in the table
      const pageNumber = offset / this.tableConfig.limit + 1;

      // Condition to avoid reloading the same page when clicking multiple times on arrows
      if (pageNumber !== Number(this.$route.query.pageNumber)) await this.$router.replace({ name: 'user-facturations', query: { pageNumber: pageNumber.toString() } });
    },
    toggleIsCreateActionEnabled() {
      this.isCreateActionEnabled = !this.isCreateActionEnabled;
      this.isCreateDiscountEnabled = false;
    },
    toggleIsCreateDiscountEnabled() {
      this.isCreateDiscountEnabled = !this.isCreateDiscountEnabled;
      this.isCreateActionEnabled = false;
    },
    async getManualActionsWithPrice() {
      const tmpActions = await apiAction.getManualActionsWithPrice(this.$route.params.userId);

      this.manualActions = tmpActions.actions;
      this.manualDiscounts = tmpActions.discounts;
    },
    async createNonAutoAction() {
      this.createLoading = true;
      try {
        const selected = this.manualActions.find((action) => action.name === this.createActionSelected);
        await apiAction.createManualAction(this.$route.params.userId, this.createActionSelected, selected.price);
        this.$notification.show({
          text: 'Facturation créée avec succès !',

        });
      } catch (error) {
        this.$notification.show({
          title: 'La création de la facturation a échoué',
          text: 'Veuillez réessayer ou contacter l\'administrateur du site',
        });
      } finally {
        this.createLoading = false;
      }
    },
    async createNonAutoDiscount() {
      this.createLoading = true;
      try {
        const selected = this.manualDiscounts.find((action) => action.name === this.createDiscountSelected);
        await apiAction.createManualAction(this.$route.params.userId, this.createDiscountSelected, selected.price);
        this.$notification.show({
          text: 'Remise créée avec succès !',

        });
      } catch (error) {
        this.$notification.show({
          title: 'La création de la remise a échoué',
          text: 'Veuillez réessayer ou contacter l\'administrateur du site',
        });
      } finally {
        this.createLoading = false;
      }
    },
  },
};
</script>

<style lang="sass">
.user-facturations
  text-align: right
  .create-button
    margin-top: 30px
  .create-content
    padding: 30px
    border: 1px solid #006D72
    border-radius: 10px
    .app-select
      margin: 30px 0
  .app-table
    position: relative
    margin-top: 100px
    .header
      padding: 0
    p.fee, p.action
      position: absolute
      margin: 0
      top: -50px
      left: 0
      font-size: 16px
      font-weight: bold
      color: #006D72
    p.action
      left: 50%
    p.action-id
      color: #006D72
      font-weight: bold
  .no-results
    text-align: center
.fade-enter-active, .fade-leave-active
  transition: opacity .5s
.fade-enter, .fade-leave-to
  opacity: 0
</style>
